import { Injectable } from '@angular/core';
import { CmxHttpClient } from './http-client.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CmxHttpOptions, CustomHeaders } from '../cmx-api-v7/projects/cmx-api-v7/src/public_api';
import { UserRegistration } from '../models/userRegistration';
import { ProvisionalUser, ProvisionalUserAlerts, ProvisionalUserValidation } from '../models/provisionalUser';
import { CustomerRegistration } from '../models/customerRegistration';
import { Role } from '../models';
import { LocalBrand } from '../models/localBrand';
import { Position } from '../models/position';

@Injectable({
  providedIn: 'root'
})
export class SsoService {

  private customerInformation: BehaviorSubject<CustomerRegistration> = new BehaviorSubject<CustomerRegistration>(null);
  private customerConfiguration: BehaviorSubject<UserRegistration[]> = new BehaviorSubject<UserRegistration[]>(null);
  private showErrorAlert: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showAlertFlags$ = new Subject<ProvisionalUserAlerts>();
  public showCustomerCard$ = new Subject<boolean>();

  constructor(
    private cmxHttp: CmxHttpClient
  ) {}

  public getAlertFlag() {
    return this.showAlertFlags$;
  }

  public getcustomerCardFlag() {
    return this.showCustomerCard$;
  }

  public setProvisionalUser(user: ProvisionalUser) {
    sessionStorage.setItem('provisional-user', JSON.stringify(user));
  }

  public setUserValidation(userValidation: ProvisionalUserValidation) {
    sessionStorage.setItem('user-validation', JSON.stringify(userValidation));
  }

  public setCustomer(customer: CustomerRegistration) {
    sessionStorage.setItem('customer', JSON.stringify(customer));
  }

  public getProvisionalUser() {
    const user = sessionStorage.getItem('provisional-user');
    const user_json = JSON.parse(user);
    return user_json as ProvisionalUser;
  }

  public getUserValidation() {
    const user_validation = sessionStorage.getItem('user-validation');
    const user_validation_json = JSON.parse(user_validation);
    return user_validation_json as ProvisionalUserValidation;
  }

  public getCustomer() {
    var customer = sessionStorage.getItem('customer');
    const customer_json = customer !== 'undefined' && customer !== null ? JSON.parse(customer) : '{}';
    return customer_json as CustomerRegistration;
  }

  public getUserProcesses(processCode: string, countryCode?: string): Observable<any> {
    let uri = `v5/gm/countries?process=${processCode}`;
    if (countryCode) {
        uri += `&countryCode=${countryCode}`;
    }
    return this.cmxHttp.get(uri)
        .pipe(map((res) => res && res['countries'] ? res['countries'] : null));
  }

  getLocalBrands(countryCode: string) {
    const uri = `v5/gm/countries/${countryCode}/brands`;
    return this.cmxHttp.get<[LocalBrand]>(uri)
      .pipe(map((res) => res ? res['localBrands'] : []));
  }

  getPositions(countryCode: string) {
    const uri = `v5/gm/countries/${countryCode}/positions`;
    return this.cmxHttp.get<[Position]>(uri)
      .pipe(map((res) => res ? res['positions'] : []));
  }

  public getCustomerInfo(customerCode: string, countryCode: string): Observable<any> {
    const uri = `v5/gm/countries/${countryCode}/customers/${customerCode}`;
    const header: CustomHeaders =  { 'process': 'SSO' }
    const options: CmxHttpOptions = { headers: header };
    return this.cmxHttp.get(uri, options).pipe(map((res) => res ? res: null));
  }

  public createProvisionalUser(payload: any) {
    return this.cmxHttp.post(`v5/gm/users`, payload);
  }

  public validateProvisionalUser(payload: any) {
    return this.cmxHttp.post(`v5/gm/users/validate`, payload);
  }

  public getProvisionalUserByToken(token: string) {
    return this.cmxHttp.get<ProvisionalUser>(`v5/gm/users?token=`+token);
  }

  public createUserByToken(payload: any) {
    const processId = 'SRS';
    return this.cmxHttp.post(`v5/gm/users?processId=`+processId, payload);
  }

  public setCustomerInformation(value: CustomerRegistration): void {
    this.customerInformation.next(value);
  }

  public getCustomerInformation(): Observable<CustomerRegistration> {
    return this.customerInformation.asObservable();
  }

  public requestNewVerificationCode(payload: any) {
    return this.cmxHttp.patch(`v5/gm/users`, payload);
  }

  public setCustomerConfiguration(value: UserRegistration[]): void {
    this.customerConfiguration.next(value);
  }

  public getCustomerConfiguration(): Observable<UserRegistration[]> {
    return this.customerConfiguration.asObservable();
  }

  public setShowErrorAlert(value: boolean): void {
    this.showErrorAlert.next(value);
  }

  public getShowErrorAlert(): Observable<boolean> {
    return this.showErrorAlert.asObservable();
  }
}
