import { Observable, of } from 'rxjs';
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService, LocationService } from '../services';
import { map, catchError } from 'rxjs/operators';
import { Logger } from '../angular-services-v8/logger.service';
import { SessionService } from '../angular-services-v8/session.service';
import { IamAuthService } from '../msal-services-v1/services/iam-auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  private sessionCookie;
  constructor(
    private router: Router,
    private sessionService: SessionService,
    private userService: UserService,
    private locationService: LocationService,
    private iamService: IamAuthService
  ) {
    this.sessionCookie = this.sessionService.readCookie('up_session');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (this.sessionService.isLoggedIn) {
      this.sessionService.clearCookie('up_session');
      return true;
    }
    const path = state.url || '/';
    return this.checkSessionByCookie().pipe(
      map((isOk) => {
        if (isOk) {
          const pathUrl = path ? this.router.parseUrl(path) : '';
          this.locationService.redirect(
            `${location.protocol}//${location.host}${pathUrl}`
          );
          return;
        }

        const toReturnUrl = state.url;
        const isRedirectToIamLogiIn = this.existsParameterInUrl(toReturnUrl, 'toiamlogin');
        const urlAuthToRedirect = isRedirectToIamLogiIn ? this.iamService.getPostLogoutRedirectUri() : '/login';

        if (toReturnUrl !== '/dashboard') {
          const toReturnCleanedUrl = this.removeParameterFromUrl(toReturnUrl, 'toiamlogin');
          this.router.navigate([urlAuthToRedirect], {
            queryParams: { returnUrl: toReturnCleanedUrl },
            queryParamsHandling: 'merge'
          });
        } else {
          this.router.navigate([urlAuthToRedirect]);
        }
        return false;
      })
    );
  }

  private checkSessionByCookie(): Observable<boolean> {
    let sessionCookieJson: any;
    if (this.sessionCookie) {
      localStorage.removeItem('language');
      // Session cookie exists, therefore generate session storage based on the cookie.
      sessionCookieJson = JSON.parse(this.sessionCookie);

      let session = {
        oauth2: {
          access_token: sessionCookieJson.auth,
          refresh_token: sessionCookieJson.refresh_token,
          region: sessionCookieJson.region,
          expires_in: sessionCookieJson.expires_in,
        },
        jwt: sessionCookieJson.jwt
      }
      session = (Object.keys(this.sessionService.dataSession).length > 0 && this.sessionService.dataSession["oauth2"]) ? this.sessionService.dataSession : session;
      this.sessionService.updateDataSession(session);

      if (window['CX_ENABLE_SESSIONSTORAGE']) {
        sessionStorage.setItem('access_token', sessionCookieJson.auth);
        sessionStorage.setItem('refresh_token', sessionCookieJson.refresh_token);
        sessionStorage.setItem('expires_in', sessionCookieJson.expires_in);
        sessionStorage.setItem('auth_token', sessionCookieJson.auth);
        sessionStorage.setItem('jwt', sessionCookieJson.jwt);
        sessionStorage.setItem('region', sessionCookieJson.region);
      }

      return this.userService.getUser().pipe(
        map((data) => {
          let session = this.sessionService.dataSession;

          session.profile = data.profile;

          session.applications = data.applications;
          session.country = data.countryCode;
          session.role = data.profile.userType;
          session.profile.userAccount = data.userAccount
          session.profile.userId = data.userId
          this.sessionService.updateDataSession(session);

          if (window['CX_ENABLE_SESSIONSTORAGE']) {
            sessionStorage.setItem('user_profile', JSON.stringify(data.profile));
            sessionStorage.setItem(
              'user_customer',
              JSON.stringify(data.customer)
            );
            sessionStorage.setItem(
              'user_applications',
              JSON.stringify(data.applications)
            );
            sessionStorage.setItem('country', data.countryCode);
            sessionStorage.setItem(
              'applications',
              JSON.stringify(data.applications)
            );
            sessionStorage.setItem('role', data.profile.userType);
            sessionStorage.setItem('username', data.userAccount);
            sessionStorage.setItem('sessionId', data.userId.toString());
          }


          // for react apps
          const _tokenData = {
            oauth2: {
              access_token: sessionCookieJson.auth,
              refresh_token: sessionCookieJson.refresh_token,
              region: sessionCookieJson.region,
              expires_in: sessionCookieJson.expires_in,
            },
            jwt: sessionCookieJson.jwt,
            profile: data.profile,
            applications: data.applications,
            customer: data.customer,
            country: data.countryCode,
            sessionId: data.userId
          };

          sessionStorage.setItem('userInfo', JSON.stringify(_tokenData));
          sessionStorage.setItem('token_data', JSON.stringify(_tokenData));

          this.sessionService.setUserProfile();
          this.sessionService.clearCookie('up_session');
          return true;
        }),
        catchError((error: any) => {
          Logger.log(error);
          return of(false);
        })
      );
    } else {
      return of(false);
    }
  }

  private existsParameterInUrl(originalUrl: string, param: string): boolean {
    try {
      const url = new URL(originalUrl, window.location.origin);
      const searchParams = new URLSearchParams(url.search);
      const paramLower = param.toLowerCase();

      for (const [key] of searchParams.entries()) {
        if (key.toLowerCase() === paramLower) {
          return true;
        }
      }
      return false;
    } catch {
      return false;
    }
  }

  private removeParameterFromUrl(originalUrl: string, param: string): string {
    try {
      const url = new URL(originalUrl, window.location.origin);
      const searchParams = new URLSearchParams(url.search);
      const paramLower = param.toLowerCase();

      const keys = Array.from(searchParams.keys());

      keys.forEach(key => {
        if (key.toLowerCase() === paramLower) {
          searchParams.delete(key);
        }
      });

      url.search = searchParams.toString();
      return url.pathname + url.search;
    } catch {
      return originalUrl;
    }
  }
}
