import { MsalGuardConfiguration, MsalInterceptorConfiguration, ProtectedResourceScopes } from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { IamSettingsService } from "../services/iam-settings.service";

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  const language = localStorage.getItem('language') ?? 'en_US';
  const error = sessionStorage.getItem('errorLogin') ?? '';
  sessionStorage.removeItem('errorLogin');
  return {
      interactionType: InteractionType.Redirect,
      authRequest: {
          scopes: [],
          extraQueryParameters: {
            ui_locales: language,
            onError: error
          }
      }
  }
}

export function MSALInstanceFactory(iamSettings: IamSettingsService): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: iamSettings.getClientId(),
      authority: iamSettings.getLoginAuthorityPolicyUri(),
      knownAuthorities: iamSettings.getKnownAuthorities(),
      redirectUri: iamSettings.getRedirectUri(),
      postLogoutRedirectUri: iamSettings.getPostLogoutRedirectUri()
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: true
    },
    system: {
      loggerOptions: {
        loggerCallback: (logLevel, message, containsPii) => {
            console.log(message);
          },
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(iamSettings: IamSettingsService): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(window['API_HOST'] + '*', [
    {
      httpMethod: 'GET',
      scopes: [iamSettings.getClientId()]
    },
    {
      httpMethod: 'POST',
      scopes: [iamSettings.getClientId()]
    },
    {
      httpMethod: 'PUT',
      scopes: [iamSettings.getClientId()]
    },
    {
      httpMethod: 'PATCH',
      scopes: [iamSettings.getClientId()]
    },
    {
      httpMethod: 'DELETE',
      scopes: [iamSettings.getClientId()]
    }
  ])

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  }
}
