export class JobSite {
  public jobSiteId: number;
  public jobSiteCode: string;
  public jobSiteDesc: string;
  public customerId: number;
  public countryCode: string;
  public regionDesc: string;
}
export class Application {
  public applicationCode: string;
  public functions: Function[];
}

export class Function {
  public functionCode: string;
}

export class Approver {
  public userId: number;
  public fullName: string;
  public userPosition: string;
}

export class Customer {
  customerId: number;
  customerCode?: string;
  customerDesc?: string;
}

export class RequestAccess {
  public functionList: FunctionRequest[];
  public jobsiteList: JobSiteRequest[];
  public userSelectedId: string;
  public authorizedBy?: number;
  public requestType: string = "S";
  public customerId?: number;
}

export class FilterApprover {
  public functionList: FunctionRequest[];
  public jobsiteList: JobSiteRequest[];
  public userSelectedId: string;
}

export class FunctionRequest {
  public functionCode: string;
  public applicationCode: string;
}

export class JobSiteRequest {
  public jobsiteCode: string;
}

export class DiscardReason {
  public discardReasonCode: string;
  public discardReasonId: number;
}

export class RequestAccessFull {
  authorizationRequestId: number;
  userId: number;
  userFullName: string;
  requestType: string;
  payload: string;
  authorizedBy: number;
  status: string;
  userEmail: string;
  authorizedByEmail: string;
  countryCode: string;
  notificationDate: string;
  dateCreate: string;
  dateUpdate: string;
  userCreateCode: string;
  userUpdateCode: string;
  notes?: string;
  customerId?: number;
}

export class UserInformationRequest {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  dateCreate: string;
}
