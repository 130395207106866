import { Injectable } from '@angular/core';
import { SessionService } from '../../../../../../../angular-services-v8/session.service';

@Injectable()
export class StoreService {

  constructor( private sessionService: SessionService){}

  get accessToken(): string | null {
    return this.sessionService.access_token;
  }
  get jwt(): string | null {
    return this.sessionService.jwt;;
  }
  get countryCode(): string | null {
    return  this.sessionService.country;
  }
  get customerId(): number | null {
    const userProfile: any = this.sessionService.profile;
    return userProfile && userProfile.customerId;
  }
  get customerCode(): string | null {
    const userLegalEntity: any = sessionStorage.getItem('user_legal_entity');
    const userLegalEntityObject: any = JSON.parse(userLegalEntity);
    return userLegalEntityObject && userLegalEntityObject.legalEntityTypeCode;
  }
  get userLegalEntity(): string | null {
    return sessionStorage.getItem('user_legal_entity');
  }
  get userProfile(): string | null {
    return this.sessionService.profile;
  }
  get language(): string | null {
    return sessionStorage.getItem('language');
  }
  public isUserLogged(): boolean {
    return this.sessionService.access_token !== null;
  }
}
