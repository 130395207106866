import { NgModule, ModuleWithProviders, Injector } from '@angular/core';
import { LoaderService } from '../services/loader.service';
import { CmxCheckboxModule } from '../cmx-checkbox/v4/src/cmx-checkbox.module';
import { CmxTabsModule } from '../../app/cmx-tabs-v4/src/cmx-tabs.module';
import { CmxTagsModule } from '../../app/cmx-tags-v4/src/tags.module';
import { SearchModule } from '../components/search/search.module';
import { BreadcrumbsModule } from '../components/breadcrumbs/breadcrumbs.module';
import { ButtonsFooterModule } from '../components/buttons-footer/buttons-footer.module';
import { SearchFunctions } from '../helpers/functions/search.functions';
import { SharedFunctions } from '../helpers/functions';
import { CmxSlideToggleModule } from '../cmx-slide-toggle/v4/src/cmx-slide-toggle.module';
import { CmxStepsModule } from '../cmx-steps/v4/src/cmx-steps.module';
import { AlertModule } from '../cmx-alert/v4/src/alert.module';
import { CmxTableModule } from '../cmx-table/v7/projects/cmx-table-v7/src/lib/cmx-table.module';
import { CmxDropdownViewModule } from '../cmx-dropdown-view/v1/src/cmx-dropdown-view.module';
import { CmxFormFieldModule } from '../cmx-form-field-v7/projects/cmx-form-field-v7/src/lib/cmx-form-field.module';
import { CmxDialogModule } from '../cmx-dialog/v7/projects/cmx-dialog-v7/src/lib/cmx-dialog.module';
import { CmxButtonModule } from '../cmx-button/v4/src/cmx-button.module';
import { CmxRadioModule } from '../cmx-radio/v4/src/cmx-radio.module';
import { CmxTooltipModule } from '../cmx-tooltip/v4/src/cmx-tooltip.module';
import { ISpinnerTags } from '../cmx-spinner-v7/projects/cmx-spinner-v7/src/lib/cmx-spinner.module';
import { CmxWebComponentsModule } from '@cmx-web-components/angular';
import { CmxDashboardComponentsModule } from '../cmx-dashboard-components/v1/projects/cmx-dashboard-components/src/lib/cmx-dashboard-components.module';
import { AngularLocalizationModule } from '../angular-localization-v7/angular-localization.module'

export class LoaderTags {
  constructor() {
    return {
      please_wait: 'Please wait',
    } as ISpinnerTags;
  }
}

@NgModule({
  imports: [
      CmxWebComponentsModule,
      CmxCheckboxModule,
    CmxTabsModule,
    CmxFormFieldModule,
    CmxDialogModule,
    CmxDropdownViewModule,
    CmxButtonModule,
    CmxRadioModule,
    CmxTagsModule,
    CmxCheckboxModule,
    CmxTooltipModule,
    CmxStepsModule,
    CmxSlideToggleModule,
    SearchModule,
    BreadcrumbsModule,
    ButtonsFooterModule,
    AlertModule,
    CmxDashboardComponentsModule,
    AngularLocalizationModule,
    CmxWebComponentsModule.forRoot()

  ],
  exports: [
    CmxWebComponentsModule,
    CmxCheckboxModule,
    CmxTabsModule,
    CmxTableModule,
    CmxFormFieldModule,
    CmxDialogModule,
    CmxDropdownViewModule,
    CmxButtonModule,
    CmxRadioModule,
    CmxTagsModule,
    CmxCheckboxModule,
    CmxTooltipModule,
    CmxSlideToggleModule,
    CmxStepsModule,
    AlertModule,
    SearchModule,
    BreadcrumbsModule,
    ButtonsFooterModule,
    CmxDashboardComponentsModule,
    AngularLocalizationModule
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [
        SearchFunctions,
        SharedFunctions,
        LoaderService,
        { provide: 'SpinnerTags', useClass: LoaderTags },
      ],
    };
  }
}
