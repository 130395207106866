import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpCemex } from '../../../../../../../angular-services-v8/http.service';
import { catchError, map } from 'rxjs/operators';
import { ICopyrightResponse } from '../interfaces/copyright.dto';

@Injectable()
export class CopyrightService {
  constructor(private httpCemex: HttpCemex) {}

  public getCopyright(): Observable<ICopyrightResponse> {
    const apiUrl: string = `v5/legal/copyrights`;
    return this.httpCemex
      .get(this.httpCemex.generateEndpoint(apiUrl))
      .pipe(map(res => res.json(), catchError(this.handleError)));
  }

  private handleError(error: Response | any): Observable<any> {
    return throwError(error);
  }
}
