import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { Observable } from 'rxjs';
import { IamAuthService } from '../msal-services-v1/services/iam-auth.service';
import { MsalGuard } from '../msal-services-v1/guards/msal.guard';

@Injectable({
  providedIn: 'root'
})
export class AuthFactoryGuard implements CanActivate {
  constructor(
    private authGuard: AuthGuard,
    private msalGuard: MsalGuard,
    private iamService: IamAuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const isAuthIamStarted = this.iamService.isAuthIamStarted();
    
    return isAuthIamStarted 
      ? this.msalGuard.canActivate(route, state)
      : this.authGuard.canActivate(route, state);
  }
}