import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginComponent } from './pages/login/login.component';
import { MsalGuard } from '@azure/msal-angular';
import { MFALoginComponent } from './mfa/components/mfa-login/mfa-login.component';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent, data: { routeName: 'S5-Login', track: true } },
  { path: 'login/b2c', component: MFALoginComponent, canActivate: [MsalGuard] },
  {
    path: 'forgotPassword',
    loadChildren: () =>
      import('src/app/pages/forgot-password/forgot-password.module').then(
        (x) => x.ForgotPasswordModule
      ),
    data: { routeName: 'S5-Forgot_password', track: true },
  },
  {
    path: 'resetPassword',
    loadChildren: () =>
      import('src/app/pages/reset-password/reset-password.module').then(
        (x) => x.ResetPasswordModule
      ),
    data: { routeName: 'S5-Reset_password', track: true },
  },
  {
    path: 'sign-on',
    loadChildren: () =>
      import('src/app/pages/sign-on/sign-on.module').then(
        (x) => x.SignOnModule
      ),
    data: { routeName: 'S5-Sign-on', track: true },
  },
  {
    path: 'public/:pageType',
    loadChildren: () =>
      import('./pages/legal-documents/legal-documents.module').then((x) => x.LegalDocumentsModule),
    data: { routeName: 'S5-Legal_Documents', track: true },
  },
  {
    path: '',
    loadChildren: () =>
      import('src/app/auth-pages/auth-pages.module').then(
        (x) => x.AuthPagesModule
      ),
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
