import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CmxHttpClient } from 'src/app/services/http-client.service';

export type MfaMethodName = 'TOTP' | 'SMS' | 'Email'
type PreferredMethodName = 'None' | MfaMethodName
type PhoneNumber = {
    country_code: string,
    phone_number: string,
}

@Injectable()
export class MFAService {

  public static MFA_SETTINGS = 'MFA_SETTINGS';

  constructor(
    private cmxHttp: CmxHttpClient,
    @Inject(MFAService.MFA_SETTINGS)
    @Optional() private mfaSettings: any
  ) {

  }

  public setPhoneNumber(phoneNumber: PhoneNumber): Observable<any> {
    return this.cmxHttp.put('v7/secm/mfa/users/phones', phoneNumber).pipe(
      map(data => data),
      catchError((error) => this.handleError(error))
    );
  }

  public getPhoneNumber(): Observable<PhoneNumber> {
    return this.cmxHttp.get('v7/secm/mfa/users/phones').pipe(
      map(data => data as PhoneNumber),
      catchError((error) => this.handleError(error))
    );
  }

  public deleteConfiguredMethod(method: MfaMethodName | string): Observable<any> {
    return this.cmxHttp.delete(`v7/secm/mfa/users/configured?method=${method}`).pipe(
      map(data => data),
      catchError((error) => this.handleError(error))
    );
  }

  public getConfiguredMethods(): Observable<string[]> {
    return this.cmxHttp.get('v7/secm/mfa/users/configured').pipe(
      map((data) => data['methods']),
      catchError((error) => this.handleError(error))
    );
  }

  public getPreferredMethod(): Observable<string> {
    return this.cmxHttp.get('v7/secm/mfa/users/preferred').pipe(
      map((data) => data['method']),
      catchError((error) => this.handleError(error))
    );
  }

  public setPreferredMethod(method: PreferredMethodName | string): Observable<{ method: PreferredMethodName }> {
    return this.cmxHttp.put('v7/secm/mfa/users/preferred', {method});
  }

  public getClientId(): string {
    if (this.mfaSettings == undefined || this.mfaSettings.clientId == undefined) {
        return null;
    } else {
        return this.mfaSettings.clientId;
    }
  }

  public getLoginUri(): string {
    if (this.mfaSettings == undefined || this.mfaSettings.authority == undefined || this.mfaSettings.loginPolicy == undefined) {
      return null;
    } else {
      return this.mfaSettings.authority + this.mfaSettings.loginPolicy;
    }
  }

  public getKnownAuthorities(): string[] {
    const authorities = [];
    if (this.mfaSettings != undefined && this.mfaSettings.authority != undefined) {
      authorities.push(this.mfaSettings.knownAuthorities);
    }
    return authorities;
  }

  public getRedirectUri(): string {
    if (this.mfaSettings == undefined || this.mfaSettings.redirectUri == undefined) {
      return null;
    } else {
      return this.mfaSettings.redirectUri;
    }
  }

  public getPostLogoutRedirectUri(): string {
    if (this.mfaSettings == undefined || this.mfaSettings.postLogoutRedirectUri == undefined) {
      return null;
    } else {
      return this.mfaSettings.postLogoutRedirectUri;
    }
  }

  public getEmailVerifyUri(): string {
    if (this.mfaSettings == undefined || this.mfaSettings.emailVerifyPolicy == undefined) {
      return null;
    } else {
      return this.mfaSettings.authority + this.mfaSettings.emailVerifyPolicy;
    }
  }

  public getPhoneVerifyUri(): string {
    if (this.mfaSettings == undefined || this.mfaSettings.phoneVerifyPolicy == undefined) {
      return null;
    } else {
      return this.mfaSettings.authority + this.mfaSettings.phoneVerifyPolicy;
    }
  }

  public getAuthenticatorVerifyUri(): string {
    if (this.mfaSettings == undefined || this.mfaSettings.authenticatorVerifyPolicy == undefined) {
      return null;
    } else {
      return this.mfaSettings.authority + this.mfaSettings.authenticatorVerifyPolicy;
    }
  }

  public getProfileB2C(): Observable<any> {
    return this.cmxHttp.get('v7/secm/users/profile?include=userinfo,profile,applications,roles,customers').pipe(
      map(data => data),
      catchError((error) => this.handleError(error))
    );
  }

  public getSignInPolicyName(): string {
    if (this.mfaSettings == undefined || this.mfaSettings.loginPolicy == undefined) {
      return null;
    } else {
      return this.mfaSettings.loginPolicy;
    }
  }
  
  public getEnrollPolicyName(method: MfaMethodName): string {
    switch (method) {
      case 'TOTP':
        return this.mfaSettings.authenticatorVerifyPolicy;
      case 'SMS':
        return this.mfaSettings.phoneVerifyPolicy;
      case 'Email':
        return this.mfaSettings.emailVerifyPolicy;
      default:
        return null;
    }
  }

  private handleError(error: any) {
    return throwError(error || 'Server error');
  }

}
