import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs/';
import { catchError, map } from 'rxjs/operators';
import { CmxHttpClient, CmxHttpOptions } from './http-client.service';

// Providers


@Injectable({
  providedIn: 'root'
})

export class PasswordService {
    private options: CmxHttpOptions = {headers: {'Content-Type': 'application/json'}};
    constructor(
        private httpClient: CmxHttpClient
    ) {
    }

    public resetPassword(request: any): Observable<any> {
        return this.httpClient.post<any>('v5/secm/user/resetpassword', request, this.options).pipe(
            map((res: any) => res),
            catchError(err => this.handleError(err))
        );
    }

    public requestResetPassword(): Observable<any> {
        return this.httpClient.post<any>('v5/gm/passwords/reset?userAccount='+sessionStorage.getItem('userAccount'), null).pipe(
            map((res: any) => res),
            catchError(err => this.handleError(err))
        );
    }

    public resetPasswordByTemporaryPassword(request: any): Observable<any> {
        return this.httpClient.post<any>('v6/secm/passwords/reseted?isTemporal=true', request, this.options).pipe(
            map((res: any) => res),
            catchError(err => this.handleError(err))
        );
    }

    public changePassword(otp: string, password: string, country: string, userAccount: string): Observable<any> {
        const data = { token: otp, password, country, userAccount };
        return this.httpClient.post<any>('v5/secm/user/changePassword/token', data, this.options).pipe(
            map((res: any) => res),
            catchError(err => this.handleError(err))
        );
    }

    public requestPasswordChange(email: string): Observable<any> {
        return this.httpClient.get<any>('v5/secm/user/changePassword?' + 'userId=' + email, this.options)
            .pipe(map((res: any) => res),
            catchError(err => this.handleError(err))
        );
    }

    public getUserProfile(token: string, countryCode: string): Observable<any> {
        return this.httpClient.get<any>('v6/secm/users/token?tokenId=' + token + '&country=' + countryCode).pipe(
            map((res: any) => res),
            catchError((error: any) => this.handleError(error))
        );
    }

    public getRulesPassword(countryCode: string) {
        return this.httpClient.get<any>('v6/secm/passwords/rules?country=' + countryCode).pipe(
            map((data: any) => data),
            catchError(this.handleError)
        );
    }

    private handleError(error: any) {
        return throwError(error || 'Server error');
    }
}
