import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  Optional,
  Inject,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { SafeResourceUrl } from '@angular/platform-browser';
import { MediaObserver } from '@angular/flex-layout';

import { Subscription, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslationService } from '../../../../../../../angular-localization-v7/services/translation.service';
import { SessionService } from '../../../../../../../angular-services-v8/session.service';
import { MenuService } from '../services/menu.service';
import { Broadcaster } from '../../../../../../../events-v7/projects/events-v7/src/lib/broadcaster.event';
import {
  IAppMenuItem,
  IUserProfile,
  ILegalEntity,
} from '../../../../../../../angular-types-v2/interfaces';

import { IApplicationMenu } from '../interfaces/menu.dto';
import { throwError } from 'rxjs';
import { CwcSidenav } from '@cmx-web-components/angular';
import { CopyrightService } from '../services/copyright.service';
import { ICopyrightResponse } from '../interfaces/copyright.dto';

export interface ILanguage {
  languageId: number;
  languageName: string;
  languageISO: string;
}

export interface ILanguageSelect {
  languageName: string;
  languageISO: string;
  isSelected: boolean;
}

export interface ICustomOption {
  iconId: string;
  labelOption: string;
  subOptions: ICustomSubOption[];
}

export interface ICustomSubOption {
  idOption: number;
  labelOption: string;
}

export interface ISidebarILegalEntity extends ILegalEntity {
  active?: boolean;
  selected?: boolean;
}

export enum NavigationType {
  Redirect,
  Router,
}

@Component({
  selector: 'cmx-sidebar',
  styleUrls: [
    './../../../../../../../../../scssv4/cmx-components/cmx-sidebar/v4/cmx-sidebar.component.scss',
    './cmx-sidebar.component.scss',
  ],
  templateUrl: './cmx-sidebar.component.html',
})
export class CmxSidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  public _customLegalEntity: ISidebarILegalEntity;
  public preSelectedCustomer: ISidebarILegalEntity;
  public _legalEntityMenuActive: boolean;
  public legalEntityMenuEnabled: boolean;
  @Input() public rtl = false;
  @Input() public hideUserPanel = false;
  @Input() public pathMobileLang = '';
  @Input() public disabledProfile = false;
  @Input() public forceRouterList: string[] = [];
  @Input() public version = 3;
  @Input() public privacy = 'Privacy';
  @Input() public privacyRoute: string;
  @Input() public legalRoute: string;
  @Input() public useRouting = false;
  @Input() public applicationCode: string;
  @Input() public variant: string;
  @Input() public enableEventListenerToggle: boolean = true;
  @Output() public collapseEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public legalEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public copyrightEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public privacyEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public legalEntityClicked: EventEmitter<ISidebarILegalEntity> = new EventEmitter<ISidebarILegalEntity>();
  public hiddenLegalEntity = false;
  public showAppNavigation = false;
  public _currentLegalEntity: ISidebarILegalEntity;
  public _searchLegalEntity = '';
  public _isWaiting = false;
  public _legalEntityList: ISidebarILegalEntity[] = [];
  public _menuItems: IApplicationMenu[];
  public _languages: ILanguageSelect[] = [];
  public _customOption: ICustomOption;
  public _notificationItems: any[] = [];
  public preSelectedLanguage: string;
  public _languageMenuActive = false;
  private _destroyed$: Subject<boolean> = new Subject<boolean>();
  public get _showFaded(): boolean {
    return this._isMobilePhone && !this.isCollapsed;
  }
  public _fullNameUser = '';
  public developerPortalUrl: SafeResourceUrl;
  public _clickedLink: IAppMenuItem;
  public _clickedApp: IApplicationMenu;
  public _isCollapsed: boolean = false;
  public menuItemIdActive: number;
  public subMenuItemIdActive: number;
  public minCharachterInput = 4;
  public noEvents = false;

  private _totalLegalEntities = 0;
  private _fetchCount = 0;
  private _showLegalMsg = true;
  private _legalEntityMsg = '';

  private _watcher: Subscription;
  private _isMobile: boolean;
  private _isMobilePhone: boolean;
  public _userProfile: IUserProfile;
  private _showLegalEntity = false;
  private _currentLang: string;
  private _currentPage = 1;

  public userTypeAccepted = 'C';
  public userCountry: string;
  public modalOpened: boolean = false;
  public entityName: string;
  public entityId: string;
  public initialLegalEntities: ISidebarILegalEntity[] = [];
  public legalEntitySaved;

  public isGetCopyright =
    window['CX_SHOW_COPYRIGHT'] && (window['CX_SHOW_COPYRIGHT'] === 'true' || window['CX_SHOW_COPYRIGHT'] === true)
      ? true
      : false;

  public lblCopyright = '';

  @ViewChild(CwcSidenav) cwcSideNav: CwcSidenav;

  @Input()
  set isCollapsed(value: boolean) {
    if (this._isCollapsed !== value) {
      this._isCollapsed = value;
    }
  }

  get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  @Input()
  set menuItems(items: IApplicationMenu[]) {
    this._menuItems = items;
  }

  @Input() private mobileBreakpoint = 1079;

  @Output()
  private clickMenuOption: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  private customOptionEvent: EventEmitter<ICustomSubOption> = new EventEmitter<ICustomSubOption>();

  @Output()
  private onModalOpenedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public get customOptions(): ICustomOption {
    return this._customOption;
  }

  public set customOptions(value: ICustomOption) {
    this._customOption = value;
  }

  @Input()
  public set customLegalEntity(value: ISidebarILegalEntity) {
    this.noEvents = !!value;
    this._customLegalEntity = value;
    this._currentLegalEntity = value;
    this._legalEntityList = [];
  }

  public get initials(): string {
    if (this._userProfile && this._userProfile.firstName.length > 0) {
      const lastName: any =
        this._userProfile.lastName.length > 0 ? this._userProfile.lastName.charAt(0).toUpperCase() : '';
      return `${this._userProfile.firstName.charAt(0).toUpperCase()}${lastName}`;
    }

    return ' ';
  }

  constructor(
    public translationService: TranslationService,
    private sessionService: SessionService,
    private menuService: MenuService,
    public media: MediaObserver,
    private router: Router,
    private eventBroadcaster: Broadcaster,
    private copyrightService: CopyrightService,
    @Optional() @Inject('RTL') isRTL: boolean,
  ) {
    this.detectMobile();
    if (isRTL !== undefined) {
      this.rtl = isRTL;
    }
    this._watcher = media
      .asObservable()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        this.detectMobile();
      });
    this.menuService.legalEntityMenuEnabled.pipe(takeUntil(this._destroyed$)).subscribe((value: boolean) => {
      this.legalEntityMenuEnabled = value;
    });
    this.userCountry = this.sessionService.country;
    this.getLanguages();
    this.getLegalEntities();
  }

  ngOnInit() {
    this.legalEntitySaved = localStorage.getItem('legalEntitySelected')
    ? JSON.parse(localStorage.getItem('legalEntitySelected'))
    : [];
    this.getMenuItems(this.applicationCode, this.legalEntitySaved.legalEntityId);
  }

  ngAfterViewInit(): void {
    this.cwcSideNav.collapsed.pipe(takeUntil(this._destroyed$)).subscribe(data => {
      this.collapseEvent.emit(data.detail);
    });

    if (this.isGetCopyright) {
      this.copyrightService
        .getCopyright()
        .pipe(takeUntil(this._destroyed$))
        .subscribe((copyright: ICopyrightResponse) => {
          this.lblCopyright =
            copyright && copyright.DocumentLanguages.length > 0
              ? this.removeTags(copyright.DocumentLanguages[0].HtmlDocument)
              : this.translationService.pt('views.footer.copyright');
        });
    }
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
    this._currentPage = 1;
  }

  public preSelectLanguage(item: any): void {
    this.preSelectedLanguage = item.value;
  }

  public showMobileLanguage(): void {
    this._legalEntityMenuActive = false;
    this.isCollapsed = false;
    if (this.pathMobileLang) {
      this.router.navigate([this.pathMobileLang]);
    } else {
      this._languageMenuActive = true;
    }
  }

  public changeLanguage(): void {
    this.translationService.setLanguage(this.preSelectedLanguage);
    this.sessionService.reloadAppMenuItems(this.applicationCode);
    this.closeLanguageSelection();
  }

  public changeCustomer(): void {
    this.clickLegalEntity(this.preSelectedCustomer);
    this.closeCustomerSelection();
  }

  public closeCustomerSelection(): void {
    this._legalEntityMenuActive = false;
    this.isCollapsed = true;
  }

  public showLegalEntities(): void {
    if (this._customLegalEntity) {
      return;
    }
    if (this._isMobilePhone) {
      this._languageMenuActive = false;
      this.isCollapsed = false;
      this._legalEntityMenuActive = true;
      return;
    }
    this.hiddenLegalEntity = !this.hiddenLegalEntity;
  }

  public toggleAppNavigation(): void {
    this.showAppNavigation = !this.showAppNavigation;
  }

  public closeLanguageSelection(): void {
    this._languageMenuActive = false;
    this.isCollapsed = true;
  }

  public getLegalEntitiesForCustomers(): void {
    this._legalEntityMsg = this.translationService.pt('views.sidebar.legal.warning');
    this._isWaiting = true;
    this.sessionService
      .getLegalEntities(0, 0)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        (result: any) => {
          this._legalEntityList = result.legalEntities as ISidebarILegalEntity[];
          for (const item of this._legalEntityList) {
            item.legalEntityDesc = item.legalEntityDesc.replace(/\s+/g, ' ');
          }
          this.initialLegalEntities = [].concat(this._legalEntityList);
          this.setDisplayLegalEntity();
          this._isWaiting = false;
        },
        (error: any) => {
          this._legalEntityList = [];
          this._isWaiting = false;
          console.error('An error occurred at getting legal entities!', error);
        },
      );
  }

  public setCurrentLegalEntityById(value: number): boolean {
    const localLegalEntity: ISidebarILegalEntity[] = this._legalEntityList.filter((item: any) => {
      return item.legalEntityId === value;
    });

    if (localLegalEntity.length > 0) {
      this.clickLegalEntity(localLegalEntity[0]);
    }
    return localLegalEntity.length > 0;
  }

  public getLegalEntityById(value: number): ISidebarILegalEntity | void {
    const localLegalEntity: ISidebarILegalEntity[] = this._legalEntityList.filter((item: any) => {
      return item.legalEntityId === value;
    });
    if (localLegalEntity.length > 0) {
      return localLegalEntity[0];
    }
    return;
  }

  public filterLegalEntities(event = null): void {
    if (event) {
      this._searchLegalEntity = event.detail;
    }
    if (!!this._userProfile && this._userProfile.userType !== 'C') {
      this._fetchCount = 0;
      this._currentPage = 1;
      this._legalEntityList = [];
      if (this._searchLegalEntity.length >= 4) {
        this._isWaiting = true;
        this._showLegalMsg = false;
        this.searchLegalEntity(100, this._currentPage, false, true);
      } else if (this._searchLegalEntity.length === 0) {
        this._legalEntityList = [].concat(this.initialLegalEntities);
      }
    }
  }

  public getMessage(count?: number): any {
    if (count !== undefined && count > 0 && this._searchLegalEntity.length > 3) {
      return `${count} ${this.translationService.pt('views.sidebar.legal.results')}`;
    } else if (count !== undefined && count === 0 && this._searchLegalEntity.length > 3) {
      return this.translationService.pt('views.sidebar.legal.no_matches');
    } else {
      return this.translationService.pt('views.sidebar.legal.warning');
    }
  }

  public preSelectLegalEntity(item: ISidebarILegalEntity): void {
    for (const legalEntity of this._legalEntityList) {
      legalEntity.selected = false;
    }
    item.selected = true;
    this.preSelectedCustomer = item;
  }

  public copyrightClick(): void {
    this.copyrightEvent.emit();
  }

  public legalClick(): void {
    this.legalEvent.emit();
    if (this.legalRoute && this.useRouting) {
      this.router.navigate([this.legalRoute]);
    } else {
      window.open('/public/legal', '_blank');
    }
  }
  public privacyClick(): void {
    this.privacyEvent.emit();
    if (this.privacyRoute && this.useRouting) {
      this.router.navigate([this.privacyRoute]);
    } else {
      window.open('/public/privacy', '_blank');
    }
  }

  @HostListener('window:resize', [])
  public onresize(): void {
    this.detectMobile();
  }

  public detectMobile(): void {
    if (window.innerWidth <= this.mobileBreakpoint) {
      if (!this._isMobile) {
        // if previous value was desktop
        // make collapsed to true
        this.isCollapsed = true;
      }
      this._isMobile = true;
      this._isMobilePhone = true;
    } else {
      this._isMobile = false;
      this._isMobilePhone = false;
    }
  }

  public searchLegalEntity(
    fetch: number = 0,
    page: number = 0,
    setDisplayEntity?: boolean,
    clearLegalEntities?: boolean,
  ): void {
    this.sessionService
      .getLegalEntities(fetch, page, this._searchLegalEntity)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        (result: any) => {
          if (clearLegalEntities) {
            this._legalEntityList = [];
          }
          this._legalEntityList = this._legalEntityList.concat(result.legalEntities);
          this._totalLegalEntities = result.totalCount;
          this._fetchCount = this._fetchCount + result.count;
          this._isWaiting = false;
          this._showLegalMsg = true;
          if (setDisplayEntity) {
            this.setDisplayLegalEntity();
          }
        },
        (error: any) => {
          console.error('An error occurred at getting legal entitieFs!', error);
        },
      );
  }

  public legalEntityRemains = (fetch: number = 0, page: number = 0, legalEntitySaved, firstLegalEntity) => {
    const { userType } = this._userProfile;
    const legalEntityTypeCode =
    legalEntitySaved && legalEntitySaved.legalEntityTypeCode ? legalEntitySaved.legalEntityTypeCode : null;

    if (userType === 'C') {
      const isLegalEntityDifferent = legalEntityTypeCode !== firstLegalEntity.legalEntityTypeCode;
      const isLegalEntityInList = !!this._legalEntityList.find(obj => obj.legalEntityTypeCode === legalEntityTypeCode);

      this._currentLegalEntity =
        isLegalEntityDifferent && isLegalEntityInList ? legalEntitySaved : this._legalEntityList[0];
    } else {
      if (legalEntityTypeCode) {
        this.sessionService
          .getLegalEntities(fetch, page, legalEntityTypeCode)
          .pipe(takeUntil(this._destroyed$))
          .subscribe({
            next: (res: any) => {
              const hasLegalEntities = res.legalEntities && res.legalEntities.length;
              this._currentLegalEntity = hasLegalEntities ? legalEntitySaved : firstLegalEntity;
              localStorage.setItem('legalEntitySelected', JSON.stringify(this._currentLegalEntity));
            },
            error: error => {
              console.error(error);
              this._currentLegalEntity = firstLegalEntity;
            },
          });
      } else {
        this._currentLegalEntity = firstLegalEntity;
        localStorage.setItem('legalEntitySelected', JSON.stringify(this._currentLegalEntity));
      }
    }
  };

  public setDisplayLegalEntity(): void {
    if (this._legalEntityList.length > 0) {
      this.legalEntityRemains(1, 1, this.legalEntitySaved, this._legalEntityList[0]);
      const sessionLegalEntity: any = sessionStorage.getItem('user_legal_entity');
      if (sessionLegalEntity && sessionLegalEntity !== undefined && sessionLegalEntity !== 'undefined') {
        try {
          this._currentLegalEntity = JSON.parse(sessionLegalEntity) as ISidebarILegalEntity;
        } catch (e) {
          const message: any = `Exception at parsing user_legal_entity from sessionStore:`;
          console.warn(message, e);
        }
      }
      this.sessionService.setLegalEntity(this._currentLegalEntity);
      // emitter
      this.legalEntityClicked.emit(this._currentLegalEntity);
      if (
        this.version === 3 &&
        !!this._userProfile &&
        this._userProfile.userType !== 'C' &&
        this._legalEntityList.length <= 1
      ) {
        this._legalEntityList = [];
      }
    }
  }

  public clickLegalEntity(item: ISidebarILegalEntity): void {
    this._fetchCount = 0;
    this._totalLegalEntities = 0;
    this._currentLegalEntity = item;
    this._searchLegalEntity = '';
    this.sessionService.setLegalEntity(item);
    this.getMenuItems(this.applicationCode, item.legalEntityId);
    this.legalEntityClicked.emit(item);
  }

  public setLegalEntityByComponent(e) {
    const entityData = e.detail;
    const entity = this._legalEntityList.find(ent => {
      return ent.legalEntityTypeCode === entityData.entityId;
    });
    if (entity) {
      localStorage.setItem('legalEntitySelected', JSON.stringify(entity));
      this.sessionService.setLegalEntity(entity);
      if(this._currentLegalEntity.legalEntityId !== entity.legalEntityId) {
        this._currentLegalEntity = entity;
        this.getMenuItems(this.applicationCode, entity.legalEntityId);
      }
      this.legalEntityClicked.emit(entity);
    }
  }

  public isActiveLegalEntity(item: ISidebarILegalEntity): boolean {
    if (this._currentLegalEntity && item) {
      return this._currentLegalEntity.legalEntityId === item.legalEntityId;
    } else {
      return false;
    }
  }

  public normalizeString(value: string): string {
    if (value === null || value === undefined) {
      return '';
    }
    const removeSymbols: string = value.replace(/"/g, '');
    // tslint:disable-next-line:max-line-length
    // const capitalizedLegalEntity: string = (removeSymbols ? removeSymbols.toLowerCase() : removeSymbols).replace(/(?:^|\s)\S/g, (a: string) => a.toUpperCase());
    return removeSymbols;
  }

  public fetchMoreEntities(): void {
    this._currentPage++;
    this.searchLegalEntity(100, this._currentPage, false);
  }

  @HostListener('scroll', ['$event'])
  public onWindowScroll(event: any): void {
    if (this._totalLegalEntities > this._fetchCount && !!this._userProfile && this._userProfile.userType !== 'C') {
      const tracker: any = event.target;
      const limit: any = tracker.scrollHeight - tracker.clientHeight;
      if (event.target.scrollTop >= limit - 1.6 && limit > 0 && event.target.scrollTop > 0) {
        this._isWaiting = true;
        this._showLegalMsg = false;
        this.fetchMoreEntities();
      }
    } else {
      event.preventDefault();
    }
  }

  public getLanguages(): void {
    this.translationService
      .getLanguages()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        (languages: any) => {
          this._languages = languages;
          this.userCountry = this.sessionService.country;
        },
        (error: any) => {
          console.log('error', error);
        },
      );

    this.translationService
      .getSelectedLanguage()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        (language: any) => {
          this._currentLang = language;
          this.userCountry = this.sessionService.country;
        },
        (error: any) => {
          console.log('error', error);
        },
      );
  }

  public getLegalEntities(): void {
    if (this._customLegalEntity) {
      this._currentLegalEntity = this._customLegalEntity;
      return;
    }

    this._userProfile = this.sessionService.userProfile;
    if (this._userProfile) {
      this._fullNameUser = `${this._userProfile.firstName} ${this._userProfile.lastName}`;

      // for customers, all its legal entities are loaded on one api call.
      if (!!this._userProfile && this._userProfile.userType === this.userTypeAccepted) {
        this.getLegalEntitiesForCustomers();
      } else {
        this.searchLegalEntity(1, 1, true);
      }
    }
  }

  public getMenuItems(applicationCode?: string, customerId?: number): void {
    this.sessionService.getDCMApplications(applicationCode, customerId)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((result: IApplicationMenu[]) => {
        this._menuItems = result;
        this._menuItems.forEach(menuItem => {
          this.identifyCurrentPathToActiveItemMenu(menuItem);
        });
      });
  }

  public onApplicationItemClick(itemClicked: IApplicationMenu): void {
    if (this._clickedApp !== itemClicked) {
      this._clickedApp = itemClicked;
    } else {
      this._clickedApp = {} as IApplicationMenu;
    }

    if (!itemClicked.menu || itemClicked.menu.menuItems.length === 0) {
      this.clickMenuOption.emit(itemClicked.applicationTitle);
      this.isCollapsed = this._isMobilePhone ? !this.isCollapsed : this.isCollapsed;
      if (this.shouldForceRouter(itemClicked.applicationUrl)) {
        this.navigateTo(itemClicked.applicationUrl, NavigationType.Router);
      } else {
        this.navigateTo(itemClicked.applicationUrl, NavigationType.Redirect);
      }
    }
  }

  public onSubItemClick(subItem: IAppMenuItem): void {
    if (this._clickedLink !== subItem) {
      this._clickedLink = subItem;
    }
    this.clickMenuOption.emit(subItem.menuTitle);
    this.isCollapsed = this._isMobilePhone ? !this._isCollapsed : this._isCollapsed;
    const pathSubItem: any = this.getMainPath(subItem.menuEndpoint);
    const isLocalHost: any = window.location.hostname.indexOf('localhost') > -1;
    // to know if it's on other application do a redirect

    if (
      this.getMainPath(window.location.pathname) === pathSubItem ||
      isLocalHost ||
      this.shouldForceRouter(subItem.menuEndpoint)
    ) {
      this.navigateTo(subItem.menuEndpoint, NavigationType.Router);
    } else {
      this.navigateTo(subItem.menuEndpoint, NavigationType.Redirect);
    }
  }

  /**
   * @description - This method is used to activate the menu item when the user is on the same page
   * @param menuToTest - The menu item to test
   * @param displayFlag - The flag to activate the menu item
   * @returns - The flag to activate the menu item
   */
  public shouldDisplayActiveMenu(menuToTest: IApplicationMenu | IAppMenuItem, displayFlag: boolean): boolean {
    const pathname: string = window.location.pathname;
    const menuToTestApp = menuToTest as IApplicationMenu;
    const menuToTestItem = menuToTest as IAppMenuItem;
    // is the pathname me or is it my child?
    const isItMe: boolean = pathname === menuToTestApp.applicationUrl;
    let isItMyChild = false;
    // if it's a application menu, check if it's my child
    if (menuToTestApp.menu && menuToTestApp.menu.menuItems) {
      isItMyChild =
        menuToTestApp.menu.menuItems.findIndex(menuItem => {
          // compare the menu endpoint with the pathname
          return menuItem.menuEndpoint.indexOf(pathname) !== -1;
        }) !== -1
          ? true
          : false;
    } else {
      // if it's a menu item, check if it's my child
      isItMyChild = menuToTestItem.menuEndpoint.indexOf(pathname) !== -1 ? true : false;
    }
    return isItMe || isItMyChild || displayFlag;
  }

  public toggleSidebar(): void {
    this.isCollapsed = !this.isCollapsed;
    if (this.version === 2) {
      this.hiddenLegalEntity = false;
      this._showLegalEntity = this.isCollapsed ? false : this._showLegalEntity;
      this._languageMenuActive = false;
      this._legalEntityMenuActive = false;
    }
  }

  public getArrowIcon(): string {
    return this._showLegalEntity ? '#icon-down-single' : '#icon-right-single';
  }

  public goToProfile(): void {
    if (!this.disabledProfile) {
      window.location.href = '/profile';
    }
  }

  public goToSettings(): void {
    if (this.pathMobileLang) {
      this.router.navigate([this.pathMobileLang]);
    } else {
      window.location.href = '/settings';
    }
  }

  public goToPrivacy(): void {
    this.privacyEvent.emit();
    if (this.privacyRoute && this.useRouting) {
      this.router.navigate([this.privacyRoute]);
    } else {
      window.open('/public/privacy', '_blank');
    }
  }

  public signOut(): void {
    this.sessionService
      .logout()
      .pipe(takeUntil(this._destroyed$))
      .subscribe((result: any) => {
        if (!result.success) {
          console.error('An error ocurred before logout:', result.message);
        }
        window.location.href = '/';
      });
  }

  public setLanguage(lang: ILanguageSelect): void {
    this.translationService.setLanguage(lang.languageISO);
    this.eventBroadcaster.broadcast(Broadcaster.DCM_LANGUAGE_CHANGE, lang.languageISO);
  }

  public getMainPath(path: string): string {
    const arr: string[] = path.split('/');
    return path.charAt(0) === '/' ? arr[1] : arr[0];
  }

  public handleError(error: Response | any): Observable<any> {
    // in a real world app, we might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body: any = error.json() || '';
      const err: any = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(error);
    return throwError(error);
  }

  public setCmxIcons(icon: string): string {
    icon = icon === 'icon-app-document-center' ? 'cmx-icon-module-document-center' : icon;
    icon = icon === 'cmx-icon-module-rccc' ? 'cmx-icon-logo' : icon;
    icon = icon === 'cmx-icon-module-localization' ? 'cmx-icon-pin-current-location' : icon;
    icon = icon === 'cmx-icon-module-parameters' ? 'cmx-icon-question' : icon;
    return icon;
  }

  private navigateTo(menuEndpoint: string, type: NavigationType): void {
    switch (type) {
      case NavigationType.Redirect:
        window.location.href = menuEndpoint;
        console.log('do a redirect:', menuEndpoint);
        break;
      case NavigationType.Router:
        this.router.navigate([menuEndpoint]);
        console.log('router navigate to', menuEndpoint);
        break;
      default:
        break;
    }
  }

  private identifyCurrentPathToActiveItemMenu(menuItem: IApplicationMenu) {
    const pathname: string = window.location.pathname;
    const isCurrentPath = pathname.includes(menuItem.menu.menuEndpoint);
    if (isCurrentPath) {
      this.menuItemIdActive = menuItem.menu.menuId;
    }
    if (menuItem.menu.menuItems.length) {
      menuItem.menu.menuItems.forEach(subMenuItem => {
        const isCurrentSubPath = pathname.includes(subMenuItem.menuEndpoint);
        if (isCurrentSubPath) {
          this.subMenuItemIdActive = subMenuItem.menuId;
        }
      });
    }
  }

  private shouldForceRouter(pathname: string): boolean {
    if (this.forceRouterList.length > 0) {
      const forcedRoutesRegExp: RegExp = new RegExp('/(' + this.forceRouterList.join('|') + ').*');
      return !!pathname.match(forcedRoutesRegExp);
    }

    return false;
  }

  public onModalOpened(open: CustomEvent): void {
    this.modalOpened = open.detail;
    this.onModalOpenedEvent.emit(open.detail);
  }

  private removeTags(str) {
    if (str === null || str === '') {
      return false;
    } else {
      str = str.toString();
    }
    return str.replace(/(<([^>]+)>)/gi, '');
  }
}
