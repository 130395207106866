import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { CountlyService } from 'src/app/helpers-v1/countly.service';
import { ErrorService, DocumentService, LocationService, LoadScriptService, StorageService } from './services';
import { LangTools } from './helpers/functions/lang.functions';
import { Broadcaster } from 'src/app/events-v7/projects/events-v7/src/lib/broadcaster.event';
import { LoaderService } from './services/loader.service';
import { FeatureToggleModule } from 'src/app/feature-toggle-service/v1/src/feature-toggle.module';
import { AuthPagesModule } from './auth-pages/auth-pages.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AppComponent } from './app.component';
import { appFeaturesConnectConfig, appFeaturesConfig } from '../toggleConfig';
export const cmx_language = window['CMX_LANGUAGES'];

// HTTP CLIENT
import { CmxHttpClient } from './services/http-client.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthService } from './services/auth.service';
//import { from } from 'rxjs';
import { HttpCemex } from './angular-services-v8/http.service';
import { ProjectSettings } from './angular-services-v8/project.settings';
import { SessionService } from './angular-services-v8/session.service';
import { SessionB2CService } from './angular-services-v8/session-b2c.service';
import { CountryConfigService } from './angular-services-v8/country-config.service';
import { TranslationService } from './angular-localization-v7/services/translation.service';
import { LocaleService } from './angular-localization-v7/services/locale.service';
import { FormatterService } from './angular-localization-v7/services/formatter.service';
import { CmxApiConfig, CmxApiModule } from './cmx-api-v7/projects/cmx-api-v7/src/lib/cmx-api.module';
import { CmxLoginModule } from './cmx-login/v7/projects/cmx-login/src/lib/cmx-login.module';
import { CmxButtonModule } from './cmx-button/v4/src/cmx-button.module';
import { CmxWizelineChatbotModule } from './cmx-wizeline-chatbot/v7/projects/cmx-wizeline-chatbot-v7/src/lib/wizeline-chatbot.module';
import { CmxChatbotModule } from './cmx-chatbot/v9/projects/cmx-chatbot-v9/src/lib/chatbot.module';
import { CmxDropdownModule } from './cmx-dropdown/v4/src/cmx-dropdown.module';
import { CmxSpinnerModule } from './cmx-spinner-v7/projects/cmx-spinner-v7/src/lib/cmx-spinner.module';
import { AlertModule } from './cmx-alert/v4/src/alert.module';
import { LanguageSelectorModule } from './components/language-selector/language-selector.module';
import { AuthRoutingModule } from './auth-pages/auth-pages.routes';
import { RecaptchaModule, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { SortingService } from './services/sorting.service';
import { UserProcessService } from './services/user-process.service';
import { CountryService } from './services/country.service';
import { WalkmeService } from './services/walkme.service';
import { SsoService } from './services/sso.service';
import { CmxWebComponentsModule } from '@cmx-web-components/angular';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './mfa/settings/msal.configuration';
import { MFAService } from './mfa/services/mfa.service';
import { CmxSkipMsalInterceptor } from './mfa/interceptors/cmx-skip-msal.interceptor';
import { CmxMsalInterceptor } from './mfa/interceptors/cmx-msal.interceptor';
import { CmxCookiebotModule } from './cmx-cookiebot-v7/cmx-cookiebot.module';
import { LanguageService } from './services/language.service';

const apiConfig: CmxApiConfig = {
  httpClient: CmxHttpClient,
};

export function appKeyProvider(): string {
  return (window as any)['ROLLOUT_APP_KEY'];
}

export const _session = (http: HttpCemex) => {
  return new SessionService(http, new Broadcaster(), 'v6');
};


export const language = () => {
  const lang = localStorage.getItem('language');
  if (lang) {
    return lang;
  }
  const defaultLanguage = window['LANGUAGE'];
  localStorage.setItem('language', defaultLanguage);
  return defaultLanguage;
};

export const connectConfig = {
  appKey: appKeyProvider,
  appVersion: appFeaturesConnectConfig.version,
  appName: appFeaturesConnectConfig.name,
  syncInterval: appFeaturesConnectConfig.syncInterval,
};

export const mfa_settings = window['MFA_SETTINGS'] ? JSON.parse(window['MFA_SETTINGS'].replace(/'/g, '"')) : {};

@NgModule({
    bootstrap: [AppComponent, MsalRedirectComponent],
    declarations: [
        AppComponent,
        LoginComponent,
        NotFoundComponent,
    ],
    imports: [
        BrowserModule,
        SharedModule.forRoot(),
        SharedModule,
        LanguageSelectorModule,
        // tslint:disable-next-line: deprecation
        HttpModule,
        HttpClientModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        AlertModule,
        CmxDropdownModule,
        CmxLoginModule,
        CmxWizelineChatbotModule,
        CmxChatbotModule,
        CmxButtonModule,
        CmxSpinnerModule,
        AppRoutingModule,
        AuthRoutingModule,
        AuthPagesModule,
        FeatureToggleModule.forRoot(connectConfig, appFeaturesConfig),
        CmxApiModule.forRoot(apiConfig),
        BrowserAnimationsModule,
        RecaptchaModule,
        CmxWebComponentsModule.forRoot(),
        MsalModule,
        CmxCookiebotModule.forRoot({widgetEnabled: true}),
    ],
    providers: [
        CmxHttpClient,
        { provide: 'ORIGIN_URL', useValue: location.origin },
        ErrorService,
        CountlyService,
        StorageService,
        HttpCemex,
        ProjectSettings,
        { provide: RECAPTCHA_LANGUAGE, useValue: "en" },
        {
            provide: SessionService,
            deps: [HttpCemex],
            useFactory: _session,
        },
        SessionB2CService,
        TranslationService,
        LocaleService,
        FormatterService,
        CountryConfigService,
        DocumentService,
        UserProcessService,
        CountryService,
        LoadScriptService,
        LocationService,
        Broadcaster,
        LoaderService,
        StorageService,
        WalkmeService,
        AuthService,
        SortingService,
        SsoService,
        MFAService,
        { provide: 'MFA_SETTINGS', useValue: mfa_settings },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CmxSkipMsalInterceptor,
          multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CmxMsalInterceptor,
          multi: true,
      },
        {
          provide: MSAL_INSTANCE,
          deps: [MFAService],
          useFactory: MSALInstanceFactory
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          deps: [MFAService],
          useFactory: MSALInterceptorConfigFactory
        },
        { provide: 'USE_TRANSLATION_SERVER', useValue: true },
        { provide: 'USE_LOCAL_MANIFEST', useValue: true },
        { provide: 'USE_RTL', useValue: false },
        { provide: 'PRODUCT_PATH', useValue: '/user-provisioning/' },
        { provide: 'TRANSLATION_PRODUCT_PATH', useValue: 'user-provisioning' },
        { provide: 'DEFAULT_LANGUAGE_ISO', useFactory: language },
        { provide: 'TRANSLATION_LANGUAGES', useValue: cmx_language },
        LangTools,
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        LanguageService,
    ],
    schemas:[
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class AppModule {}
