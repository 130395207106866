import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CmxHttpClient } from 'src/app/services/http-client.service';
import { IamBaseService } from '../../msal-services-v1/services/iam-base.service'

export type MfaMethodName = 'TOTP' | 'PHONE' | 'EMAIL'
type PreferredMethodName = 'None' | MfaMethodName
type PhoneNumber = {
  country_code: string,
  phone_number: string,
}

@Injectable()
export class MFAService extends IamBaseService {

  constructor(
    private cmxHttp: CmxHttpClient,
    protected msalService: MsalService
  ) {
    super(msalService);
  }

  public setPhoneNumber(phoneNumber: PhoneNumber): Observable<any> {
    return this.cmxHttp.put('v7/secm/mfa/users/phones', phoneNumber).pipe(
      map(data => data),
      catchError((error) => this.handleError(error))
    );
  }

  public getPhoneNumber(): Observable<PhoneNumber> {
    return this.cmxHttp.get('v7/secm/mfa/users/phones').pipe(
      map(data => data as PhoneNumber),
      catchError((error) => this.handleError(error))
    );
  }

  public deleteConfiguredMethod(method: MfaMethodName | string): Observable<any> {
    return this.cmxHttp.delete(`v7/secm/mfa/users/configured?method=${method}`).pipe(
      map(data => data),
      catchError((error) => this.handleError(error))
    );
  }

  public getConfiguredMethods(): Observable<string[]> {
    return this.cmxHttp.get('v7/secm/mfa/users/configured').pipe(
      map((data) => data['methods']),
      catchError((error) => this.handleError(error))
    );
  }

  public getPreferredMethod(): Observable<string> {
    return this.cmxHttp.get('v7/secm/mfa/users/preferred').pipe(
      map((data) => data['method']),
      catchError((error) => this.handleError(error))
    );
  }

  public setPreferredMethod(method: PreferredMethodName | string): Observable<{ method: PreferredMethodName }> {
    return this.cmxHttp.put('v7/secm/mfa/users/preferred', { method });
  }

  private handleError(error: any) {
    return throwError(error || 'Server error');
  }

  public logoutEnrollAccount(mfaMethod: MfaMethodName) {
    const account = this.findEnrollAccount(mfaMethod);
    if (account) {
      this.msalService.logoutRedirect({
        account: account,
        onRedirectNavigate: (url) => {
          return false;
        }
      }).subscribe(result => { }, error => {
        console.error('Error during logout:', error);
      });
    }
  }

  private findEnrollAccount(mfaMethod: MfaMethodName): AccountInfo | undefined {
    const policyName = this.getEnrollPolicyName(mfaMethod);
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts) {
      return accounts.find(acc => {
        const idTokenClaims = acc.idTokenClaims as { tfp?: string };
        return idTokenClaims.tfp && idTokenClaims.tfp.toUpperCase() === policyName.toUpperCase();
      });
    }
  }

  private getEnrollPolicyName(method: MfaMethodName): string {
    switch (method) {
      case 'TOTP':
        return this.getAuthenticatorPolicyName();
      case 'PHONE':
        return this.getPhonePolicyName();
      case 'EMAIL':
        return this.getEmailPolicyName();
      default:
        return null;
    }
  }

  public getEnrollPolicyUri(method: MfaMethodName): string {
    return `${this.getAuthority()}${this.getEnrollPolicyName(method)}`;
  }

}
