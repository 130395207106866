import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { AdminAuthGuard } from '../guards/admin-auth.guard';
import { ReactGuard } from '../guards/react.guard';
import { TermsConditionsGuard } from '../guards/terms-conditions.guard';
import { SeveralErrorComponent } from './several-error/several-error.component';
import { AuthPagesComponent } from './auth-pages.component';
// CMX Apps Launchers
import { CustomerInfoLauncherComponent } from './../launchers/customer-info-launcher/customer-info-launcher.component';
// CMX Apps Routes
import { baseUrls as ccBaseUrls } from '@cemex-app/cmx-module-cc';
import { ApplicationCode } from './../spa';
import { SpaFunctions } from '../helpers/functions';
import { AuthFactoryGuard } from '../guards/auth-factory.guard';

export function ccMatcher(url: UrlSegment[]) {
  const routeListRegExp = new RegExp(
    `(${Object.values(ccBaseUrls).join('|')})`
  );
  return url.length >= 1 && url[0].path.match(routeListRegExp)
    ? { consumed: url }
    : null;
}

const pagesRoutes: Routes = [
  {
    path: '',
    component: AuthPagesComponent,
    canActivate: [AuthFactoryGuard],
    children: [
      { path: 'several-error', component: SeveralErrorComponent },
      {
        path: 'user-notifications',
        loadChildren: () =>
          import('./user-notifications/user-notifications.module').then(
            (x) => x.UserNotificationsModule
          ),
        canActivate: [TermsConditionsGuard],
        data: { routeName: 'S5-user-notifications', track: true },
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./home/home.module').then((x) => x.HomeModule),
        canActivate: [TermsConditionsGuard],
        data: { routeName: 'S5-Dashboard', track: true },
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./profile/profile.module').then((x) => x.ProfileModule),
        canActivate: [TermsConditionsGuard],
        data: { routeName: 'S5-Profile', track: true },
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./settings/settings.module').then((x) => x.SettingsModule),
        canActivate: [TermsConditionsGuard],
        data: { routeName: 'S5-Settings', track: true },
      },
      {
        path: 'user-management',
        loadChildren: () =>
          import('./user-management/user-management.module').then(
            (x) => x.UserManagementModule
          ),
        canActivate: [AuthFactoryGuard, AdminAuthGuard, TermsConditionsGuard],
        data: { routeName: 'S5-User_management_console', track: true },
      },
      {
        path: 'request-access',
        loadChildren: () =>
          import('./request-access/request-access.module').then(
            (x) => x.RequestAccessModule
          ),
        canActivate: [TermsConditionsGuard],
        data: { routeName: 'S5-User-Request-Access', track: true },
      },
      {
        path: 'customer-dashboards',
        loadChildren: () =>
          import('./customer-dashboards/customer-dashboards.module').then(
            (x) => x.CustomerDashboardsModule
          ),
        canActivate: [TermsConditionsGuard],
        data: { routeName: 'S5-Customers-Dashboards', track: true },
      },
      {
        matcher: ccMatcher,
        component: CustomerInfoLauncherComponent,
        canActivate: [ReactGuard],
        data: {
          routes: Object.values(ccBaseUrls),
          routerRef: new SpaFunctions().extractGlobal(
            ApplicationCode.CUSTOMER_INFO,
            ['browserHistory']
          ),
          track: false,
        },
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(pagesRoutes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
