import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { B2CService } from '../services/b2c.service';

@Injectable({
  providedIn: 'root'
})
export class MsalGuard implements CanActivate {

  constructor(private b2CService: B2CService, private router: Router) {}

  canActivate(): boolean {
    const isLoginThroughB2C = this.b2CService.isLoginThroughB2C();
    if(isLoginThroughB2C) {
      const account = this.b2CService.getSigninAccount();
      if (account) {
        return true;
      } else {
        this.router.navigate(['/login/b2c']);
        return false;
      }
    }
    else {
      return true;
    }
  }
}
