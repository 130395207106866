import { Injectable } from '@angular/core';
import * as moment from 'moment/moment';
import { SessionService } from '../angular-services-v8/session.service';

@Injectable()
export class AuthService {

  constructor(private sessionService: SessionService){}
  /**
   * OAuth access token
   * @returns {string}
   */
  get accessToken(): string {
    return this.sessionService.access_token as string;
  }

  set accessToken(access_token: string) {
    let data = this.sessionService.dataSession;
    data.oauth2.access_token = access_token;
    this.sessionService.updateDataSession(data);
  }

  /**
   * Getter for the JWT authentication token, which is stored in sessionStorage
   * @returns {string}
   */
  get jwtToken(): string {
    return  this.sessionService.jwt; 
  }

  set jwtToken(jwt: string) {
    let data = this.sessionService.dataSession;
    data.jwt = jwt;
    this.sessionService.updateDataSession(data);
  }

  get refreshToken(): string {
    return this.sessionService.dataSession.oauth2.refresh_token; 
  }

  set refreshToken(refresh_token: string) {
    let data = this.sessionService.dataSession;
    data.oauth2.refresh_token = refresh_token;
    this.sessionService.updateDataSession(data);
  }

  set dto(data: any) {
    sessionStorage.setItem('loginDTO', JSON.stringify(data));
  }

  get dto(): any {
    return JSON.parse(sessionStorage.getItem('loginDTO')as string);
  }

  get expiryDate(): string {
    return sessionStorage.getItem('expiry_date')as string;
  }

  set expiryDate(expiry_date: string) {
    sessionStorage.setItem('expiry_date', expiry_date);
  }

  get isLoggedin(): boolean {
    return !!this.refreshToken && !!this.accessToken;
  }

  get isTokenExpired(): boolean {
    return moment().isBefore(this.getExpiration());
  }

  getExpiration() {
    const expiration = sessionStorage.getItem('expiry_date');
    const expiresAt = JSON.parse(expiration) ;
    return moment(expiresAt);
  }

  clearStorage() {
    sessionStorage.clear();
  }
}
