import { Injectable } from '@angular/core';
import { SessionService } from '../angular-services-v8/session.service';

@Injectable()
export class CountlyService {
  public defaultTrackings: any = [
    ["track_pageview", ["/ordersnproduct/app/open/*"]],
    ["track_clicks"],
    ["track_errors"],
    ["track_links"],
    ["track_forms"],
    ["collect_from_forms"]
  ];

  // tslint:disable-next-line
  public Countly: any;
  public googleDataLayer: any;

  constructor(private sessionService: SessionService) {
    this.Countly = (window as any).Countly;
    // this add the support for google analytics as described in its documents
    // beware that both countly and google analytics must run simultaneously
    this.googleDataLayer = (window as any).dataLayer || [];
  }

  public init() {
    if (this.Countly !== undefined) {
      this.addTracking("track_sessions");
      this.Countly.init();
    }
  }

  public startService(url: string, appKey: string): void {
    if (this.Countly !== undefined) {
      this.Countly.url = url;
      this.Countly.app_key = appKey;
      this.Countly.q = this.defaultTrackings;
    }
  }

  public debug(flag: boolean) {
    this.Countly.debug = flag;
  }

  public addTracking(key: string, value?: any) {
    if (this.Countly !== undefined) {
      if (!value) {
        this.Countly.q.push([key]);
      } else {
        this.Countly.q.push([key, value]);
      }
    }
  }

  public addTrackingPage(page: any) {
    if (this.Countly !== undefined) {
      this.Countly.q.push(["track_pageview", page]);
    }
  }

  public changeId(countryCode: string, newId: string) {
    if (this.Countly !== undefined) {
      this.Countly.q.push(["change_id", countryCode + newId]);
    }
  }

  public identifyUser(userfields: any) {
    if (this.Countly !== undefined) {
      this.Countly.q.push(["user_details", userfields]);
    }
  }

  public endSession() {
    if (this.Countly !== undefined) {
      this.Countly.q.push(["end_session"]);
    }
  }

  public removeTracking(trackingjson: any) {
    if (this.Countly !== undefined) {
      this.Countly.q.forEach((element: any[]) => {
        if (element.indexOf(trackingjson) !== -1) {
          element[0] = "canceled";
        }
      });
    }
  }

  public addGTM(
    applicationName?: string,
    pageTitle?: string,
    customerId?: any
  ): void {

    let currentUserCategory = "ANONYM";
    let currentUserRole = "ANONYM";
    let currentCustomerId = "ANONYM";
    let currentCountry = "ANONYM";
    let currentUserId = "ANONYM";
    if (
      this.sessionService.access_token
    ) {
      currentUserCategory = this.sessionService.profile?.isDigitalAdmin
        ? "DA"
        : "General";
      currentUserRole =
      this.sessionService.role === "C" ? "Customer" : "Internal";
      currentCustomerId =
        customerId ||
        JSON.parse(sessionStorage['user_legal_entity']).legalEntityTypeCode;
      currentCountry = this.sessionService.country || "ANONYM";
      currentUserId = this.sessionService.profile?.userId || "ANONYM";
    }
    const marketingData = {
      application: applicationName || window.location.pathname.split("/")[1],
      countryCode: currentCountry,
      customerId: currentCustomerId,
      event: "Pageview",
      pagePath: window.location.pathname,
      pageTitle: pageTitle || document.title.replace(" ", "_"),
      userCategory: currentUserCategory,
      userId: currentUserId.toString(),
      userSegment: sessionStorage.getItem("userSegment") || "ANONYM",
      userSubsegment: sessionStorage.getItem("userSubsegment") || "ANONYM",
      userType: currentUserRole
    };
    if (this.googleDataLayer) {
      this.googleDataLayer.push(marketingData);
    }
  }

  public addGTMCustomerChange(newLegalEntityCode?: string): void {
    let currentLegalEntity =
      sessionStorage.getItem("user_legal_entity") || "{}";
    currentLegalEntity =
      JSON.parse(currentLegalEntity).legalEntityTypeCode || "ANONYM";

    const event = {
      event: "customerChange",
      eventAction: "Customer change",
      eventCategory: "User interaction",
      eventLabel: newLegalEntityCode || currentLegalEntity
    };
    if (this.googleDataLayer) {
      this.googleDataLayer.push(event);
    }
  }

  public addBulkVariables(request: any): void {
    if (this.googleDataLayer) {
      this.googleDataLayer.push(request);
    }
  }
}
