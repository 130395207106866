import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse, HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap, finalize } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs/';
import { LoaderService } from '../services/loader.service';
import { AuthService } from '../services/auth.service';

const language: string = localStorage.getItem('language') || sessionStorage.getItem('language') || window as { [key: string]: any }['LANGUAGE'];
export const BYPASS_CMX_AUTH = new HttpContextToken(() => false);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  requestCount = 0;
  constructor(private authService: AuthService, private loaderService: LoaderService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requestCount++;
    this.loaderService.loaderOn = true;
    const headerSettings: { [name: string]: string | string[] } = {};
    let changedRequest: HttpRequest<any>;

    for (const key of request.headers.keys()) {
      headerSettings[key] = request.headers.getAll(key);
    }

    if (this.authService.accessToken &&
      request.url.indexOf('user/resetpassword') === -1
      && request.url.indexOf('passwords') === -1
      && request.url.indexOf('oauth2/token') === -1) {
      headerSettings['Authorization'] = `Bearer ${this.authService.accessToken}`;
      headerSettings['jwt'] = this.authService.jwtToken;
    }

    headerSettings['Accept-Language'] = "en-US";
    changedRequest = request.clone({ setHeaders: headerSettings });

    return next.handle(changedRequest).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
      }
    },
      (err: any) => {
        const reload = sessionStorage.getItem('canReloadPage');
        if(reload) {
          sessionStorage.removeItem('canReloadPage');
          window.location.reload();
          return throwError(err);
        }
        let errorMsg = '';
        if (err instanceof HttpErrorResponse) {
          errorMsg = `Status:${err.status}  Error: ${err.error?.message}`;
          if ((err.status === 401 || err.status === 403) &&
            err.url.includes('resetpassword') &&
            err.url.includes('oauth2/token') &&
            err.url.includes('passwords')) {
            this.router.navigate(['login'], { queryParams: { sessionExpired: true } });
          }
          // TODO -> refresh token
        }
        console.log(errorMsg);
        return throwError(err);
      }), finalize(() => {
        this.requestCount--;
        if (this.requestCount === 0) {
          this.loaderService.loaderOn = false;
        }
      }));
  }
}
