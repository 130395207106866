import { Component, HostListener } from '@angular/core';

@Component({
    selector: 'buttons-footer',
    templateUrl: './buttons-footer.component.html',
    styleUrls: ['./buttons-footer.component.scss']
})

export class ButtonsFooterComponent {
    navVersion = ((window['CX_VERSION'] && (window['CX_VERSION'] === "2")) ? 2 : 3);
    @HostListener('document:changeNavVersion', ['$event'])
    changeNavVersion(ev: CustomEvent) {
        // do something meaningful with it
        this.navVersion = (this.navVersion === 2 ? 3 : 2);
    }
}
