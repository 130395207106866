<div (domChange)="onDomChange(change)">
  <div class="alert" *ngIf="shareAlertVisible">
    <cwc-alert designVersion="v2" variant="error" message="{{ translationService.pt('views.user-notifications.sent-error') }}"
    duration="5000" dir="{{isRTL ? 'rtl': 'ltr'}}"></cwc-alert>
  </div>
</div>

<div class="user-notifications">
  <h1>
    {{ translationService.pt("views.user-notifications.your-notifications") }}
  </h1>
  <div class="spacer-48"></div>

  <div class="notification-filters">
    <div class="notification-filters__item">
      <cwc-input-date label="{{ translationService.pt('views.user-notifications.select-date') }}" class="width-full"
      dir="{{isRTL ? 'rtl': 'ltr'}}" designVersion="v2" placeholder="{{ translationService.pt('views.user-notifications.select-date') }}"
      language="{{_localLanguageISO}}" (cwcChange)="filterDateSelect($event.detail)" controlButtons="true">
      </cwc-input-date>
    </div>
    <div class="notification-filters__item">
      <cwc-picker id="processModules"
          footerAcceptButtonLabel="{{translationService.pt('views.global.picker-confirm')}}"
          footerCancelButtonLabel="{{translationService.pt('views.global.picker-cancel')}}"
          filterPlaceholder="{{translationService.pt('views.userManagement.searchPlaceholderDefault')}}"
          filterEmptyMessage="{{translationService.pt('views.userManagement.noResults')}}"
          label="{{translationService.pt('views.user-notifications.process')}}"
          class="notification-filters__picker"
          (cwcChange)="processSelected($event)"
          placeholder="{{ translationService.pt('views.user-notifications.all-processes') }}"
          designVersion="v2" class="width-full">
        <option value="">
          {{ translationService.pt("views.user-notifications.all-processes") }}
        </option>
        <ng-container *ngFor="let p of _userNotificationProcessModules">
          <option [ngValue]="p.code" *ngIf="p.code !== 'S5'" [value]="p.code">
            {{ p.desc }}
          </option>
        </ng-container>
      </cwc-picker>
    </div>
    <div class="notification-filters__item" *ngIf="_userNotificationSubprocessModules.length > 0">
      <cwc-picker *ngIf="_userNotificationSubprocessModules.length > 0" id="subprocessModules"
          footerAcceptButtonLabel="{{translationService.pt('views.global.picker-confirm')}}"
          footerCancelButtonLabel="{{translationService.pt('views.global.picker-cancel')}}"
          filterPlaceholder="{{translationService.pt('views.userManagement.searchPlaceholderDefault')}}"
          filterEmptyMessage="{{translationService.pt('views.userManagement.noResults')}}"
          class="notification-filters__picker"
          label="{{translationService.pt('views.user-notifications.sub-process')}}"
          (cwcChange)="subprocessSelected($event)"
          placeholder="{{translationService.pt('views.user-notifications.all-processes')}}"
          designVersion="v2" class="width-full">
        <option value="">
          {{ translationService.pt("views.user-notifications.all-processes") }}
        </option>
        <ng-container *ngFor="let s of _userNotificationSubprocessModules">
          <option [ngValue]="s.code" [value]="s.code">{{ s.desc }}</option>
        </ng-container>
      </cwc-picker>
    </div>
  </div>
  <div class="spacer-24"></div>
  <user-notifications-group
    *ngIf="_groupToday"
    [userNotificationList]="_userNotificationShortList | userNotificationsFilterGroup : 'D'"
    [subtitleTranslation]="translationService.pt('views.user-notifications.today')"
    [shareNotificationTranslation]="translationService.pt('views.user-notifications.share-notification')"
    (showUserNotificationDetail)="showUserNotificationDetail($event)"
    (showUserNotificationShare)="showUserNotificationShare($event)"
  ></user-notifications-group>

  <user-notifications-group
    *ngIf="_groupMonth"
    [userNotificationList]="_userNotificationShortList | userNotificationsFilterGroup : 'M'"
    [subtitleTranslation]="translationService.pt('global.monthNames').split(',')[_monthPage]"
    [shareNotificationTranslation]="translationService.pt('views.user-notifications.share-notification')"
    (showUserNotificationDetail)="showUserNotificationDetail($event)"
    (showUserNotificationShare)="showUserNotificationShare($event)"
  ></user-notifications-group>

  <user-notifications-group
    *ngIf="_groupPrevious"
    [userNotificationList]="_userNotificationShortList | userNotificationsFilterGroup : 'P'"
    [subtitleTranslation]="translationService.pt('views.user-notifications.previous')"
    [shareNotificationTranslation]="translationService.pt('views.user-notifications.share-notification')"
    (showUserNotificationDetail)="showUserNotificationDetail($event)"
    (showUserNotificationShare)="showUserNotificationShare($event)"
  ></user-notifications-group>

  <ng-container *ngIf="_userNotificationShortList?.length == 0">
    <cwc-empty-result icon="information-rounded-fill" header="{{ translationService.pt('views.header.no-notifications') }}"
    dir="{{isRTL == true ? 'RTL' : 'LTR'}}" *ngIf="_selectedProcessCode == ''">
    </cwc-empty-result>
    <cwc-empty-result icon="information-rounded-fill" header="{{ translationService.pt('views.header.no-notifications.subprocess') }}"
    dir="{{isRTL == true ? 'RTL' : 'LTR'}}" *ngIf="_selectedProcessCode != ''">
      {{ translationService.pt('views.header.no-notifications.subprocess.content') }}
    </cwc-empty-result>
  </ng-container>

  <div *ngIf="_pageMax > 1 && _userNotificationShortList?.length > 0">
    <div class="spacer-24"></div>
    <div  class="pagination">
      <cwc-pagination activePage="{{_pageNow}}" totalPages="{{_pageMax}}" cursorBuffer="2" dir="{{isRTL ? 'rtl': 'ltr'}}"
      (cwcChange)="changePageTo($event.detail)"></cwc-pagination>
    </div>
  </div>
</div>

<cwc-modal open="{{notificationShareDialog}}" dir="{{isRTL ? 'rtl': 'ltr'}}" designVersion="v2" (cwcClose)="notificationShareDialogClose()">
  <header slot="header">
    <h3> {{ translationService.pt("views.user-notifications.share-notification") }}</h3>
    <div class="spacer-24"></div>
  </header>
  <div>
    <div class="cmx-row__modulo" *ngIf="_viewingNotification">
      <div class="user-notifications cmx-row">
        <div class="cmx-row__shared-row">
          <div class="cmx-column__icon" *ngIf="_viewingNotification">
            <div [ngSwitch]="_viewingNotification.processCode">
              <div *ngSwitchCase="'S1'" class="cmx-user-notifications__entry-icon-service">
                <span class="cmx-icon-module-customer-information font-true-blue" aria-hidden="true"></span>
              </div>
              <div *ngSwitchCase="'S2'">
                <span class="cmx-icon-module-order-product-catalogue font-true-blue" aria-hidden="true"></span>
              </div>
              <div *ngSwitchCase="'S3'">
                <span class="cmx-icon-module-track font-true-blue" aria-hidden="true"></span>
              </div>
              <div *ngSwitchCase="'S4'">
                <span class="cmx-icon-module-invoices-payments font-true-blue" aria-hidden="true"></span>
              </div>
              <div *ngSwitchCase="'S5'">
                <span class="cmx-icon-module-user-management font-true-blue" aria-hidden="true"></span>
              </div>
            </div>
          </div>
          <div>
            <span>{{ _viewingNotification.descProcessCode }} - {{ _viewingNotification.descSubProcessCode }}</span>
            <div class="cmx-row__modulo-summary">
              <span> {{ _viewingNotification.summary }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="spacer-16"></div>
    <cwc-input (cwcChange)="onEmailInput($event)" label="{{translationService.pt('views.user-notifications.email-to')}}"
    autocomplete="off" placeholder="{{translationService.pt('views.user-notifications.emails-separators-info')}}" type="text"
    name="email" class="cmx-dialog__email-box" [status]="shareEmailsOnError ? 'error' : 'regular'"
    statusMessage="{{shareEmailsOnError ? translationService.pt('views.user-notifications.unrecognized-emails') + ' ' + shareEmailsOnError : ''}}"
    #email required designVersion="v2">
    </cwc-input>
    <cwc-textarea (cwcChange)="onMessageInput($event)" label="{{translationService.pt('views.user-notifications.add-personal-message')}}"
    maxlength="500" placeholder="Check This out!" class="cmx-dialog__message-box" #message required designVersion="v2" rows="5">
    </cwc-textarea>
  </div>
  <footer slot="footer">
    <div class="spacer-16"></div>
    <div class="align-end">
      <cwc-loader *ngIf="shareForm.valid && sendingUserNotification" class="loader" size="small"></cwc-loader>
      <cwc-button (cwcClick)="sendUserNotificationShare()" variant="primary" designVersion="v2" size="small"
      disabled="{{shareForm.valid && !sendingUserNotification ? false : true}}">
        {{ translationService.pt("views.user-notifications.send-message") }}
      </cwc-button>
    </div>
  </footer>
</cwc-modal>

<cwc-modal open="{{notificationDetailDialog}}" dir="{{isRTL ? 'rtl': 'ltr'}}" designVersion="v2" (cwcClose)="notificationDetailDialog = false">
  <header slot="header">
    <h3>{{ _viewingNotification?.summary }}</h3>
    <div class="spacer-24"></div>
  </header>
  <iframe class="cmx-dialog_user-notification__content" [srcdoc]="_viewingNotification?.content | safeHtml"></iframe>
</cwc-modal>

<cwc-modal open="{{notificationShareSuccess}}" dir="{{isRTL ? 'rtl': 'ltr'}}" designVersion="v2" (cwcClose)="notificationShareSuccess = false">
  <div class="modal-content">
    <cwc-icon name="accepted-ok" size="10rem" color="bright-green" class="modal-icon"></cwc-icon>
    <div class="spacer-32"></div>
    <h3>{{ translationService.pt("views.user-notifications.sent") }}</h3>
    <div class="spacer-32"></div>
  </div>
</cwc-modal>
