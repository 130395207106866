import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BYPASS_MSAL } from './cmx-msal.interceptor';
import { BYPASS_CMX_AUTH } from '../../interceptors/auth.interceptor';

@Injectable()
export class CmxSkipMsalInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let changedRequest: HttpRequest<any>;
    const loginThroughB2C = sessionStorage.getItem('loginThroughB2C');
    const isLoginThroughB2C = (loginThroughB2C !== null && loginThroughB2C !== undefined && loginThroughB2C === 'true');
    isLoginThroughB2C ? changedRequest = request.clone({ context: new HttpContext().set(BYPASS_CMX_AUTH, true) }) :
                  changedRequest = request.clone({ context: new HttpContext().set(BYPASS_MSAL, true) });
    return next.handle(changedRequest);
  }
}
