import { UserNotificationsModule } from './user-notifications/user-notifications.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-pages.routes';
import { AdminAuthGuard } from '../guards/admin-auth.guard';
import { AuthGuard } from '../guards/auth.guard';
import { RouterModule } from '@angular/router';
import { ReactGuard } from '../guards/react.guard';
import { TermsConditionsGuard } from '../guards/terms-conditions.guard';
import { UserService, CustomerService } from '../services';
import { UserNotificationV2Service } from '../services/user-notification.service';

import { SeveralErrorComponent } from './several-error/several-error.component';
import { AuthPagesComponent } from './auth-pages.component';

// CMX Apps Launchers
import { CustomerInfoLauncherModule } from '../launchers/customer-info-launcher/customer-info-launcher.module';
import { CmxDialogModule } from '../cmx-dialog/v7/projects/cmx-dialog-v7/src/lib/cmx-dialog.module';
import { CmxButtonModule } from '../cmx-button/v4/src/cmx-button.module';
import { CmxWizelineChatbotModule } from '../cmx-wizeline-chatbot/v7/projects/cmx-wizeline-chatbot-v7/src/lib/wizeline-chatbot.module';
import { CmxChatbotModule } from '../cmx-chatbot/v9/projects/cmx-chatbot-v9/src/lib/chatbot.module';
import { CmxNavHeaderModule } from '../cmx-nav-header/v8/projects/cmx-nav-header-v8/src/lib/cmx-nav-header.module';
import { UserNotificationService } from '../cmx-nav-header/v8/projects/cmx-nav-header-v8/src/lib/services/user-notification.service';
import { CmxSidebarModule } from '../cmx-sidebar/v8/projects/cmx-sidebar-v8/src/lib/cmx-sidebar.module';
import { CmxFooterModule } from '../cmx-footer/v7/projects/cmx-footer-v7/src/lib/cmx-footer.module';
import { LangTools } from '../helpers/functions/lang.functions';
import { LanguageSelectorModule } from '../components/language-selector/language-selector.module';
import { DareminderModalModule } from '../components/modals/dareminder/dareminder.modal.module';
import { CmxWebComponentsModule } from '@cmx-web-components/angular';
import { CustomizeNotificationsModal } from '../components/modals/customize-notifications/customize-notifications.modal';
import { B2CService } from '../mfa/services/b2c.service';


@NgModule({
  imports: [
    CommonModule,
    CmxNavHeaderModule,
    CmxSidebarModule,
    CmxFooterModule,
    CmxDialogModule,
    CmxButtonModule,
    CmxWizelineChatbotModule,
    CmxChatbotModule,
    RouterModule,
    AuthRoutingModule,
    CustomerInfoLauncherModule,
    UserNotificationsModule,
    LanguageSelectorModule,
    DareminderModalModule,
    CmxWebComponentsModule.forRoot(),
  ],
  exports: [
    AuthPagesComponent
  ],
  declarations: [
    AuthPagesComponent,
    SeveralErrorComponent,
    CustomizeNotificationsModal
  ],
  providers: [
    AdminAuthGuard,
    AuthGuard,
    ReactGuard,
    TermsConditionsGuard,
    UserService,
    CustomerService,
    UserNotificationService,
    UserNotificationV2Service,
    LangTools,
    B2CService,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class AuthPagesModule {}
