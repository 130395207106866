import { Injectable } from '@angular/core';
import {
  ICustomer,
  IUserProfile
} from '../angular-types-v2/index.interface';


@Injectable()
export class SessionB2CService {
  enable_sessionStorage = window['CX_ENABLE_SESSIONSTORAGE'] || false;
  private _userProfile: IUserProfile = null;
  private _siteDomain = window['SITE_DOMAIN'] || '';

  constructor() {
    if (
      localStorage.getItem('language') === undefined ||
      (localStorage.getItem('language') === null &&
        (sessionStorage.getItem('language') !== undefined && sessionStorage.getItem('language') !== null))
    ) {
      localStorage.setItem('language', sessionStorage.getItem('language'));
    }
  }

  public saveProcessDataFromLogin(data:any){
    sessionStorage.setItem('_cmx_', this.encrypt(JSON.stringify(data)));

    if(this.enable_sessionStorage){
      sessionStorage.setItem('access_token', data.oauth2.access_token);
      sessionStorage.setItem('refresh_token', data.oauth2.refresh_token);
      sessionStorage.setItem('expires_in', data.oauth2.expires_in);
      sessionStorage.setItem('jwt', data.jwt);
      sessionStorage.setItem('auth_token', data.oauth2.access_token);
      sessionStorage.setItem('user_profile', JSON.stringify(data.profile));
      sessionStorage.setItem('user_customer', JSON.stringify(data.customer));
      sessionStorage.setItem('user_applications', JSON.stringify(data.applications));
      // react
      sessionStorage.setItem('userInfo', JSON.stringify(data));
      sessionStorage.setItem('token_data', JSON.stringify(data));
      sessionStorage.setItem('applications', JSON.stringify(data.applications));    
      sessionStorage.setItem('region', data.oauth2.region);
      sessionStorage.setItem('username', data.profile.userAccount);
    }

    this.createCookie('CemexGoRegion', data.oauth2.region, null);
    this.setUserProfile();
    sessionStorage.setItem('sessionId', data.profile.userId);
    sessionStorage.setItem('role', data.role);
    sessionStorage.setItem('country', data.country);
  }
  public encrypt(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  public decrypt(str) {
    return str ? decodeURIComponent(escape(window.atob(str))) : str;
  }

  public get dataSession(){
    let data = sessionStorage.getItem("_cmx_");
    return data ?  JSON.parse(this.decrypt(data)) : {} ;
  }
 
  public setUserProfile(): void {
    const tmpProfile: IUserProfile = this.dataSession["profile"];
    if (tmpProfile) {
      const userCustomer:ICustomer = this.dataSession["customer"];
      if (userCustomer) {
        tmpProfile.customer = userCustomer;
      }
      this._userProfile = tmpProfile;
    }
  }

  public createCookie(name: string, value: string, days?: number) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = date.toUTCString();
    } else {
      days = 0;
    }

    const cookie = `${name}=${value};expires=${expires};domain=${this._siteDomain};path=/`;
    document.cookie = cookie;
  }

  public convertJsonTo<T>(obj: string): T {
    try {
      const tmpJson = JSON.parse(obj);
      return tmpJson as T;
    } catch (e) {
      console.warn('Exception at parsing object from sessionStore:', e);
    }

    return null;
  }

}