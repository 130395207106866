import { Component } from '@angular/core';
import { MFAService } from '../../services/mfa.service';
import { SessionB2CService } from 'src/app/angular-services-v8/session-b2c.service';
import { IMsalToken, IMsalTokenKeys } from '../../models/msalCache';
import { Router } from '@angular/router';
import { CookiebotUser } from '@cemex/cmx-cookiebot-v7';
import { CookiebotService } from 'src/app/cmx-cookiebot-v7/services/cmx-cookiebot.service';
import { CookiebotUserService } from 'src/app/cmx-cookiebot-v7/services/cmx-cookiebot-user.service';
import { MsalBroadcastService } from '@azure/msal-angular';
import { B2CService } from '../../services/b2c.service';
import { filter, takeUntil } from 'rxjs/operators';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';

@Component({
  selector: 'mfa-login',
  templateUrl: './mfa-login.component.html',
})
export class MFALoginComponent {
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private router: Router,
    private sessionService: SessionB2CService,
    private mfaService: MFAService,
    private cookiebotService: CookiebotService,
    private cookiebotUserService: CookiebotUserService,
    private b2cService: B2CService,
    private msalBroadcastService: MsalBroadcastService
  ) {
  }

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      sessionStorage.setItem('loginThroughB2C', 'true');
      this.mfaService.getProfileB2C().subscribe((data) => {
        const userInformation = this.convertData(data);
        this.sessionService.saveProcessDataFromLogin(userInformation);
        this.saveConsent(userInformation);
        this.router.navigate(['/dashboard']);
      }, error => {
        console.error('Error during get profile:', error?.message);
        this.b2cService.logoutB2C();
      });
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private convertData(data: any): any {
    const userInformation = {};
    const msalTokenKeysJson: string = sessionStorage.getItem('msal.token.keys.' + this.mfaService.getClientId());
    const msalTokenKeys: IMsalTokenKeys = this.sessionService.convertJsonTo<IMsalTokenKeys>(msalTokenKeysJson);
    const access_token: IMsalToken = this.sessionService.convertJsonTo<IMsalToken>(sessionStorage.getItem(msalTokenKeys.accessToken[0]));
    const id_token: IMsalToken = this.sessionService.convertJsonTo<IMsalToken>(sessionStorage.getItem(msalTokenKeys.idToken[0]));
    const refresh_token: IMsalToken = this.sessionService.convertJsonTo<IMsalToken>(sessionStorage.getItem(msalTokenKeys.refreshToken[0]));

    const oauth2 = {};
    oauth2['access_token'] = access_token.secret;
    oauth2['refresh_token'] = refresh_token.secret;
    oauth2['expires_in'] = refresh_token.expiresOn;
    userInformation['oauth2'] = oauth2;
    userInformation['jwt'] = id_token.secret;
    const user = data.users[0];
    const profile = {
      userId: user.userId,
      customerId: user.customer.customerId,
      status: user.status.userStatus,
      userAccount: user.userAccount,
      fullName: user.fullName,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      userType: user.type.userType,
      accountType: user.account.accountType,
      userPosition: data.profile.userPosition,
      userSelectedId: user.userSelected.userSelectedId,
      isDigitalAdmin: user.isDigitalAdmin,
      hasTemporaryPassword: data.profile.hasTemporaryPassword,
      canChangePassword: user.canChangePassword
    };
    userInformation['profile'] = profile;

    const customer = {
      customerId: data.customers.customerId,
      customerCode: data.customers.customerCode,
      customerDesc: data.customers.customerDesc,
      userId: data.customers.userId
    };
    userInformation['customer'] = customer;

    userInformation['country'] = data.users[0].country.countryCode.trim();
    userInformation['role'] = data.users[0].type.userType;

    const applications = [];
    data.applications.forEach(app => {
      const application = {
        applicationId: app.applicationId,
        applicationName: app.applicationName,
        applicationCode: app.applicationCode,
        applicationDesc: app.applicationDesc,
        isVisible: app.isVisible
      };

      const roles = [];
      if (data.roles != undefined && data.roles != null) {
        data.roles.filter(rol => rol.application.applicationId == app.applicationId).forEach(r => {
          const rol = {
            roleId: r.roleId,
            roleCode: r.roleCode,
            roleName: r.roleName,
            roleDesc: r.roleDesc,
            roleType: r.roleType,
            isVisible: r.isVisible
          };
          roles.push(rol);
        });
      }
      application['roles'] = roles;
      applications.push(application);
    });

    userInformation['applications'] = applications;
    return userInformation;
  }

  private saveConsent(user: any) {
    return new Promise<void>((resolve) => {
      const consent = this.cookiebotService.getConsent();
      if (!consent) {
        resolve();
      }
      const userData: CookiebotUser = {
        ExecutedBy: user.userAccount,
        ChangeType: 'LOGIN',
        ChangeEntityId: user.userId,
        ChangeEntityTable: null,
        Action: 'CookieConsent',
        Notes: JSON.stringify(consent),
      };

      this.cookiebotUserService.saveConsentLogger(userData).subscribe((response: any) => {
        resolve();
      }, error => {
        console.log('Error while saving consent log: ', error);
      });
    });
  }
}
