import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { CmxCheckboxModule } from "../../../../../../cmx-checkbox/v4/src/cmx-checkbox.module";
import { CmxDatepickerModule } from '../../../../../../cmx-datepicker/v4/src/cmx-datepicker.module';

import { CmxTableComponent } from './components/cmx-table/cmx-table.component';
import { CmxPaginationComponent } from './components/cmx-pagination/cmx-pagination.component';
import { CmxDatepickerFilterComponent } from './components/cmx-datepicker-filter/cmx-datepicker-filter.component';
import { CmxHeaderCellComponent } from './components/cmx-header-cell/cmx-header-cell.component';
import { CmxFilterSorterComponent } from './components/cxm-filter-sorter/cmx-filter-sorter.component';
import { CmxCheckboxFiltersComponent } from './components/cmx-checkbox-filters/cmx-checkbox-filters.component';
import { CmxTableItemDirective } from './directives/cmx-table-item.directive';
import { CmxCellDirective } from './directives/cmx-cell.directive';
import { HeaderPlaceholderDirective } from './directives/cmx-header-placeholder.directive';
import { RowPlaceholderDirective } from './directives/cmx-row-placeholder.directive';
import { CmxHeaderTemplateDirective } from './directives/cmx-header-template.directive';
import { CmxRowTemplateDirective } from './directives/cmx-row-template.directive';
import { WatcherService } from './services/watcher.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    CmxCheckboxModule,
    CmxDatepickerModule,
],
declarations: [
    CmxTableComponent,
    CmxCheckboxFiltersComponent,
    CmxRowTemplateDirective,
    CmxHeaderTemplateDirective,
    RowPlaceholderDirective,
    HeaderPlaceholderDirective,
    CmxHeaderCellComponent,
    CmxFilterSorterComponent,
    CmxCellDirective,
    CmxTableItemDirective,
    CmxDatepickerFilterComponent,
    CmxPaginationComponent,
],
exports: [
    CmxTableComponent,
    CmxCheckboxFiltersComponent,
    CmxRowTemplateDirective,
    CmxHeaderTemplateDirective,
    CmxFilterSorterComponent,
    RowPlaceholderDirective,
    HeaderPlaceholderDirective,
    CmxHeaderCellComponent,
    CmxCellDirective,
    CmxTableItemDirective,
    CmxDatepickerFilterComponent,
    CmxPaginationComponent,
],
providers: [
    WatcherService,
]
})
export class CmxTableModule { }
