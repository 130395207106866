import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CookiebotUser } from '../models';
import { CmxHttpClient } from 'src/app/services/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class CookiebotUserService {
  constructor(private http: CmxHttpClient) { }

  public saveConsentLogger(body: CookiebotUser): Observable<any> {
    const apiUrl = 'v5/secm/audit/log';
    return this.http.post(apiUrl, body);
  }
}
