import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizationsRequest, Request, SSORequest } from '../models';
import { AuthorizationRequest } from '../models';
import { CmxHttpClient } from './http-client.service';
import { Application, Approver, JobSite, RequestAccessFull, UserInformationRequest } from '../models/request-access';
import { HttpHeaders } from '@angular/common/http';
import { AuthorizationRequestSSO, JobSiteSSO } from '../models/authorizationRequestSSO';


@Injectable()
export class RequestService {

  private userInformationRequest: BehaviorSubject<UserInformationRequest> = new BehaviorSubject<UserInformationRequest>(null);
    private showErrorAlert: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private showMessageAlert: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


    constructor(private cmxHttp: CmxHttpClient) {
    }

    getRequestsByStatus(status?: string) {
        const uri = `v5/secm/requests?include=${status}`;
        return this.cmxHttp.get<Request[]>(uri);
    }

    getRequestsForUser(userId: number) {
        const uri = `v5/secm/requests/user/${userId}`;
        return this.cmxHttp.get<Request[]>(uri);
    }

    getMyRequests() {
        return this.cmxHttp.get<Request[]>('v5/secm/requests');
    }

    getUserRequests() {
        return this.cmxHttp.get<AuthorizationsRequest[]>('v5/gm/users/requests').pipe(
          map((res) => res === null ? [] : res['authorizationsRequest'] ? res['authorizationsRequest'] : []));
    }

    /**
     * Gets authorizable data for request
     * @param requestId
     * @param userId (Logged in User)
     * @param [requestType]
     * @returns Filter request data depending on Digital Administrator permissions.
     */
    getAuthorizableDataForRequest(requestId: number, userId: number, requestType?: 'D' | 'R') {
        let uri = `v6/secm/authorizationrequests/${requestId}?userId=${userId}`;
        if (requestType) {
            uri = `${uri}&requestType=${requestType}`;
        }
        return this.cmxHttp.get<AuthorizationRequest[]>(uri).pipe(map((res) => res ['authorizationRequest']));
    }

    submitPermissionRequest(payload: any) {
        return this.cmxHttp.post('v5/secm/request/accessrequest', payload);
    }

    submitDataAccessRequest(payload: any) {
      return this.cmxHttp.post('v5/secm/request/datarequest', payload);
    }

    authorizeRequest(requestId: number) {
        const uri = `v5/secm/request/authorize/${requestId}`;
        return this.cmxHttp.patch(uri, null);
    }

    rejectRequest(requestId: number, notes: string) {
        const uri = `v5/secm/request/reject/${requestId}`;
        return this.cmxHttp.patch(uri, { notes });
    }

    readRequest(requestId: number) {
        const uri = `v5/secm/request/read/${requestId}`;
        return this.cmxHttp.patch(uri, null).pipe(map((res) => res as {[key: string]: any} ['status']));
    }

    cancelRequests(requestIds: string) {
        return this.cmxHttp.patch('v5/secm/request/cancel', requestIds).pipe(map((res) => res as {[key: string]: any} ['status']));
    }

    getUserRequestsById(requestId: number) {
      let uri = `v5/gm/users/requests/${requestId}`;
      const headers= new HttpHeaders().set('process', 'SSO');
      return this.cmxHttp.get<AuthorizationRequestSSO>(uri,{ 'headers': headers })
        .pipe(map((res) => res ? res : null));
    }

    getUserRequestsFullById(requestId: number) {
      let uri = `v5/gm/users/requests/${requestId}`;
      return this.cmxHttp.get<RequestAccessFull>(uri).pipe(map((res) => res ? res : null));
    }

    getUserRequestDetailById(requestId: number) {
        let uri = `v5/gm/users/requests/${requestId}`;
        return this.cmxHttp.get<SSORequest>(uri).pipe(map((res) => res ? res : null));
    }

    getUserJobSites() {
      let uri = `v5/gm/users/jobsites`;
      return this.cmxHttp.get<JobSiteSSO[]>(uri)
      .pipe(map((res) => res ? res['jobSites'] : []));
    }

    getApprovers(customerId: number, payload: any) {
      const uri = `v5/gm/customers/${customerId}/approvers`;
      return this.cmxHttp.post(uri, payload).pipe(map((res) => res['users'] ? res['users'] : []));
    }

    deleteRequestsById(requestId: number) {
        let uri = `v5/gm/users/requests/${requestId}`;
        return this.cmxHttp.delete(uri).pipe(map((res) => res ? res : null));
    }

    updateApproverRequests(requestId: number,approverId: number) {
        const uri = `v6/secm/requests/${requestId}?authorizedBy=${approverId}`;
        return this.cmxHttp.patch(uri,null).pipe(map((res) => res ? res : null));
    }

    getAplications() {
      return this.cmxHttp.get<[Application]>('v5/gm/functions')
        .pipe(map((res) => res['applications'] ? res['applications'] : []));
    }

    getDiscardReasons() {
      return this.cmxHttp.get<[Application]>('v3/im/discardreasons')
        .pipe(map((res) => res['discardReasons'] ? res['discardReasons'] : []));
    }

    rejectUserRequest(requestId: number, payload: any) {
      const uri = `v6/secm/requests/${requestId}/rejected`;
      return this.cmxHttp.patch(uri, payload);
    }

    approvUserRequest(requestId: number, payload: any = null) {
      const uri = `v6/secm/requests/${requestId}/approved`;
      return this.cmxHttp.patch(uri, payload);
    }

    public setShowErrorAlert(value: boolean): void {
      this.showErrorAlert.next(value);
    }

    public getShowErrorAlert(): Observable<boolean> {
      return this.showErrorAlert.asObservable();
    }

    public setShowMessageAlert(value: boolean): void {
      this.showMessageAlert.next(value);
    }

    public getShowMessageAlert(): Observable<boolean> {
      return this.showMessageAlert.asObservable();
    }

    public setUserInformationRequest(value: UserInformationRequest): void {
      this.userInformationRequest.next(value);
    }

    public getUserInformationRequest(): Observable<UserInformationRequest> {
      return this.userInformationRequest.asObservable();
    }

    public submitUserAccessRequest(payload: any) {
      return this.cmxHttp.post('v6/secm/users/requests', payload);
    }

    submitAccessRequest(payload: any) {
      return this.cmxHttp.post('v5/gm/users/requests', payload);
    }

    getJobSites() {
        return this.cmxHttp.get<JobSite[]>('v5/gm/users/jobsites')
          .pipe(map((res) => res['jobSites'] ? res['jobSites'] : []));
    }
}
