import { MsalGuardConfiguration, MsalInterceptorConfiguration, ProtectedResourceScopes } from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { MFAService } from "../services/mfa.service";


export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  let language = localStorage.getItem('language');
  language = language ? language : 'en_US'

  return {
      interactionType: InteractionType.Redirect,
      authRequest: {
          scopes: [],
          extraQueryParameters: {
            ui_locales: language
          }
      }
  }
}

export function MSALInstanceFactory(mfaService: MFAService): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: mfaService.getClientId(),
      authority: mfaService.getLoginUri(),
      knownAuthorities: mfaService.getKnownAuthorities(),
      redirectUri: mfaService.getRedirectUri(),
      postLogoutRedirectUri: mfaService.getPostLogoutRedirectUri()
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1
    },
    system: {
      loggerOptions: {
        loggerCallback: (logLevel, message, containsPii) => {
            console.log(message);
          },
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(mfaService: MFAService): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(window['API_HOST'] + '*', [
    {
      httpMethod: 'GET',
      scopes: [mfaService.getClientId()]
    },
    {
      httpMethod: 'POST',
      scopes: [mfaService.getClientId()]
    },
    {
      httpMethod: 'PUT',
      scopes: [mfaService.getClientId()]
    },
    {
      httpMethod: 'DELETE',
      scopes: [mfaService.getClientId()]
    }
  ])

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  }
}
