import { Injectable } from '@angular/core';

@Injectable()
export class IamSettingsService {
  private iamSettings = window['IAM_SETTINGS'] ? JSON.parse(window['IAM_SETTINGS'].replace(/'/g, '"')) : {};

  constructor(
  ) { }

  public getClientId(): string {
    if (this.iamSettings == undefined || this.iamSettings.clientId == undefined) {
      return null;
    } else {
      return this.iamSettings.clientId;
    }
  }

  public getAuthority(): string {
    if (this.iamSettings == undefined || this.iamSettings.authority == undefined) {
      return null;
    } else {
      return this.iamSettings.authority;
    }
  }

  public getKnownAuthorities(): string[] {
    const authorities = [];
    if (this.iamSettings != undefined && this.iamSettings.knownAuthorities != undefined) {
      authorities.push(this.iamSettings.knownAuthorities);
    }
    return authorities;
  }

  public getRedirectUri(): string {
    if (this.iamSettings == undefined || this.iamSettings.redirectUri == undefined) {
      return null;
    } else {
      return this.iamSettings.redirectUri;
    }
  }

  public getPostLogoutRedirectUri(): string {
    if (this.iamSettings == undefined || this.iamSettings.postLogoutRedirectUri == undefined) {
      return null;
    } else {
      return this.iamSettings.postLogoutRedirectUri;
    }
  }

  public getSignInPolicyName(): string {
    if (this.iamSettings == undefined || this.iamSettings.loginPolicy == undefined) {
      return null;
    } else {
      return this.iamSettings.loginPolicy;
    }
  }

  public getEmailPolicyName(): string {
    if (this.iamSettings == undefined || this.iamSettings.emailVerifyPolicy == undefined) {
      return null;
    } else {
      return this.iamSettings.emailVerifyPolicy;
    }
  }

  public getPhonePolicyName(): string {
    if (this.iamSettings == undefined || this.iamSettings.phoneVerifyPolicy == undefined) {
      return null;
    } else {
      return this.iamSettings.phoneVerifyPolicy;
    }
  }

  public getAuthenticatorPolicyName(): string {
    if (this.iamSettings == undefined || this.iamSettings.authenticatorVerifyPolicy == undefined) {
      return null;
    } else {
      return this.iamSettings.authenticatorVerifyPolicy;
    }
  }

  public getLoginAuthorityPolicyUri(): string {
    return `${this.getAuthority()}${this.getSignInPolicyName()}`;
  }
}
