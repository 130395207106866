import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { IamAuthService } from '../services/iam-auth.service';

@Injectable({
  providedIn: 'root'
})
export class MsalGuard implements CanActivate {

  constructor(
    private iamService: IamAuthService, 
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const account = this.iamService.getSigninAccount();
    if (account) {
      return true;
    }
    else {
      if (state.url !== '/dashboard') {
        this.router.navigate([this.iamService.getPostLogoutRedirectUri()], {
          queryParams: { returnUrl: state.url },
        });
      } else {
        this.router.navigate([this.iamService.getPostLogoutRedirectUri()]);
      }
      return false;
    }
  }
}
