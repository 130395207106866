import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BYPASS_MSAL } from './cmx-msal.interceptor';
import { BYPASS_CMX_AUTH } from '../../interceptors/auth.interceptor';
import { B2CService } from '../services/b2c.service';

@Injectable()
export class CmxSkipMsalInterceptor implements HttpInterceptor {
  constructor(private b2cService: B2CService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let changedRequest: HttpRequest<any>;
    const isLoginThroughB2C = this.b2cService.isLoginThroughB2C();
    changedRequest = !isLoginThroughB2C ? request.clone({ context: new HttpContext().set(BYPASS_MSAL, true) }) : request;
    return next.handle(changedRequest);
  }
}
