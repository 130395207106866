import { Injectable, Injector } from "@angular/core";
import { IamAuthService } from "../msal-services-v1/services/iam-auth.service";
import { MsalInterceptor } from "@azure/msal-angular";
import { AuthInterceptor } from "./auth.interceptor";


@Injectable({
    providedIn: 'root'
  })
  export class AuthInterceptorFactory {
    constructor(
      private injector: Injector,
      private iamService: IamAuthService
    ) {}
  
    createInterceptor() {
      const url = window.location.href;
      const authUrl = `${window.location.protocol}//${window.location.host}/login`;
      const hosthUrl = `${window.location.protocol}//${window.location.host}`;
  
      if (url.includes(this.iamService.getPostLogoutRedirectUri()) || this.iamService.isLoginByIAM()) {
        sessionStorage.setItem('authIamStarted', 'true');
        return this.injector.get(MsalInterceptor);
      }else if (url === authUrl || url === hosthUrl || url === `${hosthUrl}/`) {
        sessionStorage.setItem('authIamStarted', 'false');
        return this.injector.get(AuthInterceptor);
      }else {
        return this.injector.get(AuthInterceptor);
      }
    }
  }
