import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookiebotUser } from '@cemex/cmx-cookiebot-v7';
import { CookiebotService } from 'src/app/cmx-cookiebot-v7/services/cmx-cookiebot.service';
import { CookiebotUserService } from 'src/app/cmx-cookiebot-v7/services/cmx-cookiebot-user.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { IamAuthService } from '../../services/iam-auth.service';
import { filter, takeUntil } from 'rxjs/operators';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { CountryService } from 'src/app/services';
import { MsalErrorConstants } from '../../constants/msal-error.constants';

@Component({
  selector: 'iam-login',
  templateUrl: './iam-login.component.html',
})
export class IAMLoginComponent {
  private readonly _destroying$ = new Subject<void>();
  public countryConfiguration: any;
  private europeHost = window['HOST_EUROPE'];
  private americaHost = window['HOST_AMERICA'];
  private urlRoute: string;
  private routeAfterLogin: 'dashboard' | 'resetPassword';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cookiebotService: CookiebotService,
    private cookiebotUserService: CookiebotUserService,
    private iamService: IamAuthService,
    private msalBroadcastService: MsalBroadcastService,
    private countryService: CountryService
  ) {
  }

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        var chatbotElement = document.getElementById('widget');
        if (chatbotElement != null  || chatbotElement != undefined) {
          chatbotElement.setAttribute('style', 'display: none')
        }
        sessionStorage.setItem('loginByIAM', 'true');
        this.urlRoute = this.route.snapshot.queryParams['returnUrl'] || '';

        const signinAccount = this.iamService.getSigninAccount();
        const userCountry = signinAccount.idTokenClaims['country'].toString().trim();

        this.countryService.getConfigurationParametersByCountryAll().subscribe(
          (countries) => {
            this.countryConfiguration = countries;
            if (this.countryConfiguration.length > 0) {
              const filteredCountry = this.countryConfiguration?.filter(countryCode => countryCode.countryCode?.trim() === userCountry);
              const userRegion = filteredCountry && filteredCountry.length > 0 ? filteredCountry[0].region : undefined;
              const regionHost = userRegion === 'EURO' ? this.europeHost : this.americaHost;

              if (regionHost != window.location.host) {
                const baseUrl = `${window.location.protocol}//${regionHost}`;
                window.location.href = `${baseUrl}${this.iamService.getPostLogoutRedirectUri()}?returnUrl=${encodeURIComponent(this.urlRoute)}`;
              } else {
                this.iamService.getUserProfile().subscribe((data) => {
                  const userInformation = this.convertData(data, userRegion);
                  this.saveProcessDataFromLogin(userInformation);
                  this.saveConsent(userInformation);

                  const hasTemporaryPassword =
                      userInformation &&
                      userInformation.profile &&
                      userInformation.profile.hasTemporaryPassword;
                    const canChangePassword =
                      userInformation &&
                      userInformation.profile &&
                      userInformation.profile.canChangePassword;

                    if (hasTemporaryPassword && canChangePassword) {
                      this.routeAfterLogin = 'resetPassword';
                      const tempPasswordHandler = {
                        username: userInformation.profile.userAccount,
                        firstName: userInformation.profile.firstName,
                        lastName: userInformation.profile.lastName,
                        country: userInformation.country,
                      };
                      this.iamService.createCookie(
                        'cmxTmpSession',
                        JSON.stringify(tempPasswordHandler)
                      );
                    } else {
                      this.iamService.clearCookie('cmxTmpSession');
                      this.routeAfterLogin = 'dashboard';
                    }

                    const destinationUrl =this.urlRoute ? this.urlRoute : this.routeAfterLogin
                    this.router.navigateByUrl(destinationUrl);

                 }, error => {
                  sessionStorage.setItem('errorLogin', MsalErrorConstants.VIEWS_LOGIN_IAM_ERROR_PROFILE);
                  this.iamService.logoutIAM();
                });
              }
            }
          },
          (error) => {
            sessionStorage.setItem('errorLogin', MsalErrorConstants.VIEWS_LOGIN_IAM_ERROR_CONTRY);
            this.iamService.logoutIAM();
          }
        )
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private convertData(data: any, userRegion: string): any {
    const userInformation = {};
    const oauth2 = {
      region: userRegion
    };

    userInformation['oauth2'] = oauth2;
    const user = data.users[0];
    const profile = {
      userId: user.userId,
      customerId: user?.customer?.customerId,
      status: user.status.userStatus,
      userAccount: user.userAccount,
      fullName: user.fullName,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      userType: user.type.userType,
      accountType: user.account.accountType,
      userPosition: data.profile.userPosition,
      allowInformationShare: data.profile.allowInformationShare,
      allowEmailUpdates: data.profile.allowEmailUpdates,
      userSelectedId: user.userSelected.userSelectedId,
      isDigitalAdmin: user.isDigitalAdmin,
      hasTemporaryPassword: data.profile.hasTemporaryPassword,
      canChangePassword: user.canChangePassword,
      languageId: data.profile.languageId,
      cloudContactMethodId: data.profile.cloudContactMethodId ?? undefined,
      cloudContactTypeId: data.profile.cloudContactTypeId ?? undefined,
      cloudContactCode: data.profile.cloudContactCode ?? undefined,
      countryAreaCode: data.profile.countryAreaCode ?? undefined
    };
    userInformation['profile'] = profile;

    const customer = {
      customerId: user.customer?.customerId,
      customerCode: user.customer?.customerCode,
      customerDesc: user.customer?.customerDesc,
      userId: user.customer?.userId
    };

    userInformation['customer'] = !user.customer ? null : customer;
    userInformation['customerId'] = user.customer ?? 0;
    userInformation['country'] = data.users[0].country.countryCode.trim();
    userInformation['role'] = data.users[0].type.userType;

    const applications = [];
    data.applications.forEach(app => {
      const application = {
        applicationId: app.applicationId,
        applicationName: app.applicationDesc,
        applicationCode: app.applicationCode,
        applicationDesc: app.applicationDesc,
        isVisible: app.isVisible
      };

      const roles = [];
      if (data.roles != undefined && data.roles != null) {
        data.roles.filter(rol => rol.application.applicationId == app.applicationId).forEach(r => {
          const rol = {
            roleId: r.roleId,
            roleCode: r.roleCode,
            roleName: r.roleName,
            roleDesc: r.roleDesc,
            roleType: r.roleType,
            isVisible: r.isVisible
          };
          roles.push(rol);
        });
      }
      application['roles'] = roles;
      applications.push(application);
    });

    userInformation['applications'] = applications;
    return userInformation;
  }

  private saveConsent(user: any) {
    return new Promise<void>((resolve) => {
      const consent = this.cookiebotService.getConsent();
      if (!consent) {
        resolve();
      }
      const userData: CookiebotUser = {
        ExecutedBy: user.profile.userAccount,
        ChangeType: 'LOGIN',
        ChangeEntityId: user.profile.userId,
        ChangeEntityTable: null,
        Action: 'CookieConsent',
        Notes: JSON.stringify(consent),
      };

      this.cookiebotUserService.saveConsentLogger(userData).subscribe((response: any) => {
        resolve();
      }, error => {
        console.log('Error while saving consent log: ', error);
      });
    });
  }

  private saveProcessDataFromLogin(data:any){
    sessionStorage.setItem('_cmx_', this.iamService.encrypt(JSON.stringify(data)));

    //TODO: Review the region value AME or EURO instead of US or UK
    this.iamService.createCookie('CemexGoRegion', data.oauth2.region, null);

    sessionStorage.setItem('sessionId', data.profile.userId);
    sessionStorage.setItem('role', data.role);
    sessionStorage.setItem('country', data.country);
    sessionStorage.setItem('username', data.profile.userAccount);
  }
}
