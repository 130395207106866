import { APP_INITIALIZER, Injector, ModuleWithProviders, NgModule, PLATFORM_ID } from '@angular/core';
import { CookiebotComponent } from './components/cmx-cookiebot.component';
import { CookiebotService } from './services/cmx-cookiebot.service';
import { cookiebotFactory } from './core/cmx-cookiebot.factory';
import { CookiebotConfig } from './models/cmx-cookiebot.config';
import { DefaultCookiebotConfig } from './core/cmx-default-config';
import { CookiebotUserService } from './services/cmx-cookiebot-user.service';
import { SessionService } from '../angular-services-v8/session.service';


@NgModule({
  declarations: [CookiebotComponent],
  imports: [],
  exports: [CookiebotComponent],
})

export class CmxCookiebotModule {
  static forRoot(cookiebotConfig?: CookiebotConfig): ModuleWithProviders <CmxCookiebotModule> {
    return {
      ngModule: CmxCookiebotModule,
      providers: [
        {provide: CookiebotConfig, useValue: cookiebotConfig || DefaultCookiebotConfig },
        {provide: CookiebotService, useClass: CookiebotService, deps: [CookiebotConfig, CookiebotUserService, PLATFORM_ID, SessionService, Injector]},
        {provide: APP_INITIALIZER, useFactory: cookiebotFactory, deps: [CookiebotService, PLATFORM_ID], multi: true},
      ],
    };
  }
}
