export class AuthorizationRequest {
  authorizationRequestId: number;
  authorizationRequestType: AuthorizationRequestType;
  canAuthorize: boolean;
  dateCreate: string;
  dateUpdate: string;
  authorizationRequestCode?: string;
}

export class AuthorizationRequestType {
  authorizationRequestTypeCode: 'applications' | 'roles' | 'jobsites' | 'customers';
}

export class AuthorizationsRequest {
  authorizationRequest: SSORequest[];
}

export class SSORequest {
  public authorizationRequestId?: number;
  public userId?: number;
  public userFullName: string;
  public requestType: string;
  public payload: string;
  public authorizedBy?: number;
  public status: string;
  public userEmail: string;
  public countryCode: string;
  public notificationDate: string;
  public dateCreate: string;
  public dateUpdate: string;
  public userCreateCode: string;
  public userUpdateCode: string;
}
