<cmx-nav-header
  *ngIf="sessionService.isLoggedIn"
  [rtl]="isRTL"
  [useInternalSettings]="true"
  [customPathSettings]="'/settings'"
  [version]="navVersion"
  [variant]="userCountry === 'IL' ? 'negative' : 'positive'"
  (sidenavToggle)="sidenavToggle()"
  [isValidUser]="!isSelfServiceRegistration"
  [logoutPath]="loginURL"
></cmx-nav-header>
<div *ngIf="!sessionService.isLoggedIn" class="cmx-static-header">
  <a routerLink="/"><img [src]="'https://www.cemexgo.com/cdn/logos/svg/' + logo"></a>
</div>
<cmx-sidebar
  *ngIf="!isSelfServiceRegistration"
  [rtl]="isRTL"
  (legalEntityClicked)="changeLegalEntity()"
  [pathMobileLang]="'/settings'"
  [applicationCode]="applicationCode"
  [forceRouterList]="forceRouterList"
  [version]="navVersion"
  [legalRoute]="'/public/legal'"
  [privacy]="translationService.pt('views.footer.privacyLink')"
  [privacyRoute]="'public/privacy'"
  [isCollapsed]="isCollapsed"
  [enableEventListenerToggle]="false"
></cmx-sidebar>
<div class="content {{'nav-version--' + navVersion}}" [ngClass]="{'content--public': isSelfServiceRegistration}" [dir]="dir"
  [class.collapsed]="isCollapsed">
  <ng-container *ngIf="!isSelfServiceRegistration">
    <img data-tid="cmx-page-img-banner" *ngIf="displayBanner()" class="banner" [src]="banner?.bannerUrlImg"
      [attr.href]="bannerURL()" (click)="goToBannerUrl()">
  </ng-container>
  <div class="content-view">
    <router-outlet></router-outlet>
  </div>
</div>
<cmx-footer
  *ngIf="navVersion === 2"
  [rtl]="isRTL"
  [privacyRoute]="'public/privacy'"
  [legalRoute]="'/public/legal'"
  [legal]="translationService.pt('views.footer.legalLink')"
  [privacy]="translationService.pt('views.footer.privacyLink')"
  [copyright]="translationService.pt('views.footer.copyright')"
></cmx-footer>

<cwc-modal open="{{warningModal}}" dir="{{isRTL ? 'rtl': 'ltr'}}" designVersion="v2" (cwcClose)="warningModal = false">
  <div class="modal-content">
    <cwc-icon name="warning" size="10rem" color="bright-orange-dark" class="modal-icon"></cwc-icon>
    <div class="spacer-32"></div>
    <h2>
      {{ translationService.pt("views.errorCodes.OutOfServiceN500") }} <br/><br/>
    </h2>
    <div class="spacer-8"></div>
    <h3>
      500 {{ translationService.pt("views.errorCodes.InternalServerError500") }}
    </h3>
    <div class="spacer-32"></div>
  </div>
</cwc-modal>

<div *ngIf="countryHasChatbot">
  <cmx-wizeline-chatbot
    [ngClass]="{ isOnDashboard: isOnDashboard }"
  ></cmx-wizeline-chatbot>
</div>
<div *ngIf="countryHasChatbotv2" class="chatbot">
  <cmx-chatbot [ngClass]="{ isOnDashboard: isOnDashboard }"></cmx-chatbot>
</div>

<dareminder-modal #dareminder [imgUrl]="imgURL" [cemexgoLink]="cemexgoLink"></dareminder-modal>

<customize-notifications-modal [viewModal]="showNotificationSettingsModal" [userProcessCat]="userProcessCat"
  [userProcesses]="userProcesses" [userId]="userId" (eventModal)="closeNotifications()">
</customize-notifications-modal>
