import { EndSessionRequest, EventMessage, EventType } from '@azure/msal-browser';
import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';
import { CmxHttpClient } from 'src/app/services/http-client.service';
import { IamBaseService } from './iam-base.service';

@Injectable()
export class IamAuthService extends IamBaseService {
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private cmxHttp: CmxHttpClient,
    protected msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    super(msalService);
  }

  public logoutIAM(urlToRedirect?: string) {
    sessionStorage.removeItem('loginByIAM');

    let logoutRequest: EndSessionRequest = {}

    if (urlToRedirect) {
      logoutRequest.postLogoutRedirectUri = `${this.getPostLogoutRedirectUri()}'?returnUrl='${urlToRedirect}`;
    }

    const signinAccount = this.getSigninAccount();
    if (signinAccount) {
      logoutRequest.account = signinAccount;
    }

    this.msalService.logoutRedirect(logoutRequest).subscribe(result => {
      this.msalService.instance.setActiveAccount(null);
    }, error => {
      console.error('Error during logout:', error);
      this.clearSession();
    });
  }

  public handleMsalEvents() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.error('IAM Login failed:', result.error);
        let error: any = result.error;
        error = this.extractErrorDescription(error.errorMessage);
        sessionStorage.setItem('errorLogin', error);
        this.msalService.logoutRedirect();
      });
  }

  public unsubscribeMsalEvents() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private extractErrorDescription(errorMessage: string): string {
    const descriptionMatch = errorMessage.match(/Description: ([^:]+: [^\.]+)\./);
    return descriptionMatch ? descriptionMatch[1] : '';
  }

  public getUserProfile(): Observable<any> {
    return this.cmxHttp.get('v7/secm/users/profile?include=userinfo,profile,applications,roles,customers').pipe(
      map(data => data),
      catchError((error) => this.handleError(error))
    );
  }

  private handleError(error: any) {
    return throwError(error || 'Server error');
  }
}
