import {
    Component, Input, OnDestroy, AfterViewInit,
    ViewChildren, QueryList, forwardRef, Host,
} from '@angular/core';

import { Subscription, BehaviorSubject, Observable } from 'rxjs';

import { CmxCheckboxComponent } from '../../../../../../../../cmx-checkbox/v4/src/components/cmx-checkbox.component';

import { Filter } from '../../classes/filter.class';
import { WatcherService } from '../../services/watcher.service';
import { CmxTableComponent } from '../cmx-table/cmx-table.component';

@Component({
    selector: 'cmx-checkbox-filters',
    templateUrl: './cmx-checkbox-filters.component.html',
    styleUrls: ['./../../../../../../../../../../scss/cmx-table/v1/cmx-checkbox-filters.component.scss']
})
export class CmxCheckboxFiltersComponent implements AfterViewInit, OnDestroy {
    @Input()
    set filters($value: Filter[]) {
        if ($value !== undefined && $value !== null) {
            this._filters = $value;
        }
    }
    get filters(): Filter[] {
        return this._filters;
    }
    @Input() public allOptionName = '';
    @Input() public enableSearch = false;
    @Input() public minCharactersBeforeSearch = 4;
    @Input() public searchPlaceholder = '';
    @Input() public showAllOption = false;

    @ViewChildren(forwardRef(() => CmxCheckboxComponent))
    public checkboxes: QueryList<CmxCheckboxComponent>;
    public searchTerm = '';

    public allFilter: Filter = new Filter('All', null, false, null);
    public _filters: Filter[];
    public isRtl = false;

    private activeFilters: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private checkboxSubscriptions: any[] = [];

    constructor(private watcher: WatcherService, @Host() private parent: CmxTableComponent) {
        this.isRtl = this.parent.rtl;
    }

    public ngAfterViewInit(): void {
        this.listenToCheckboxes();
    }

    public ngOnDestroy(): void {
        this.dropSubscriptions();
    }

    public listenToActiveFilters(): Observable<boolean> {
        return this.activeFilters.asObservable();
    }

    /**
     * @description Checks all filters when the 'All' checkbox is clicked.
     * @param boolean $event: The event received by the change event of the checkbox.
     */
    public toggleAll($event: boolean): void {
        if (typeof $event === 'boolean') {
            this.allFilter.active = $event;
            this._filters.forEach((filter) => {
                if (filter.visible) {
                    filter.active = $event;
                }
            });
        }
    }

    /**
     * @description Toggles the 'active' attribute of a filter to enable or disable.
     * @param Filter filter: The Filter object to toggle.
     * @param boolean $event: The event received by the change event of the checkbox.
     */
    public toggleFilter(filter: Filter, $event: boolean) {
        if (typeof $event === 'boolean') {
            filter.active = $event;
            if (!this.showAllOption) {
                return;
            }
            if (!$event) {
                this.allFilter.active = false;
            } else {
                let $filtersOnFalse = 0;
                this.filters.forEach(($filter) => {
                    if ($filter.active === false) {
                        $filtersOnFalse++;
                    }
                });
                this.allFilter.active = ($filtersOnFalse === 0) ? true : false;
            }
        }
    }

    public clearSearch(): void {
        this.searchTerm = '';
        this.filters.forEach(($filter) => {
            $filter.visible = true;
        });
    }

    public searchInFilter(term: string) {
        if (term.length >= this.minCharactersBeforeSearch || term === '') {
            const aux = term.toLowerCase();
            if (aux !== '') {
                this.filters.filter((row) => {
                    if (row.name && row.name.toLowerCase().includes(aux)) {
                        row.visible = true;
                    } else {
                        row.visible = false;
                    }
                });
            } else {
                this.clearSearch();
            }
        }
    }

    private emitFilters(): void {
        this.watcher.emitActiveFilters(this.parent.assignedId, this._filters);
    }

    private emitActiveCheckboxes(): void {
        const activeFilters: any[] = [];
        this._filters.forEach( ( filter ) => {
            if (filter.active) {
                activeFilters.push( filter );
            }
        });
        if (activeFilters.length > 0) {
            this.activeFilters.next(true);
        } else {
            this.activeFilters.next(false);
        }
    }

    private listenToCheckboxes(): void {
        this.checkboxes.forEach(($checkbox: CmxCheckboxComponent) => {
            const $subscription = $checkbox.change.subscribe(($checked: any) => {
                this.emitFilters();
                this.emitActiveCheckboxes();
            });
            this.checkboxSubscriptions.push($subscription);
        });
    }

    private dropSubscriptions(): void {
        this.checkboxSubscriptions.forEach(($sub: Subscription) => $sub.unsubscribe());
        this.checkboxSubscriptions = [];
    }
}
