import { Injectable} from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { IamSettingsService } from './iam-settings.service'
@Injectable()
export class IamBaseService extends IamSettingsService {

  private _siteDomain = window['SITE_DOMAIN'] || '';

  constructor(
    protected msalService: MsalService,
  ) {
    super();
  }

  public isLoginByIAM() {
    const loginByIAM = sessionStorage.getItem('loginByIAM');
    return (loginByIAM !== null && loginByIAM !== undefined && loginByIAM === 'true');
  }

  public isAuthIamStarted() {
    const authIamStarted = sessionStorage.getItem('authIamStarted');
    return (authIamStarted !== null && authIamStarted !== undefined && authIamStarted === 'true');
  }

  public getLoginAuthorityPolicyUri(): string {
    if (!this.getAuthority() && !this.getSignInPolicyName()) {
      return null;
    } else {
      return this.getAuthority + this.getSignInPolicyName();
    }
  }

  public getSigninAccount(): AccountInfo {
    return this.findAccountByPolicyName(this.getSignInPolicyName());
  }

  private findAccountByPolicyName(policyName: string): AccountInfo | undefined {
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts) {
      return accounts.find(acc => {
        const idTokenClaims = acc.idTokenClaims as { tfp?: string };
        return idTokenClaims.tfp && idTokenClaims.tfp.toUpperCase() === policyName.toUpperCase();
      });
    }
  }

  public clearSession() {
    sessionStorage.clear();
    localStorage.clear();
    this.msalService.instance.setActiveAccount(null);
  }

  public createCookie(name: string, value: string, days?: number) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = date.toUTCString();
    } else {
      days = 0;
    }

    const cookie = `${name}=${value};expires=${expires};domain=${this._siteDomain};path=/`;
    document.cookie = cookie;
  }

  public encrypt(str: string | number | boolean) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  public decrypt(str: string) {
    return str ? decodeURIComponent(escape(window.atob(str))) : str;
  }
}