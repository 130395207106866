import { AccountInfo } from '@azure/msal-browser';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { MFAService, MfaMethodName } from './mfa.service';

@Injectable()
export class B2CService {

  constructor(
    protected msalService: MsalService,
    private mfaService: MFAService,
  ) { }

  public isLoginThroughB2C() {
    const loginThroughB2C = sessionStorage.getItem('loginThroughB2C');
    return (loginThroughB2C !== null && loginThroughB2C !== undefined && loginThroughB2C === 'true');
  }

  public logoutB2C() {
    const signinAccount = this.getSigninAccount();
    if (signinAccount) {
      sessionStorage.removeItem('loginThroughB2C');
      const logoutRedirectUri = this.mfaService.getPostLogoutRedirectUri();
      this.msalService.logoutRedirect({
        account: signinAccount,
        postLogoutRedirectUri: logoutRedirectUri
      }).subscribe(result => {
        this.msalService.instance.setActiveAccount(null);
      }, error => {
        console.error('Error during logout:', error);
        this.clearSession();
      });
    }
  }

  public logoutEnrollAccount(mfaMethod: MfaMethodName) {
    const account = this.findEnrollAccount(mfaMethod);
    if (account) {
      this.msalService.logoutRedirect({
        account: account,
        onRedirectNavigate: (url) => {
          return false;
        }
      }).subscribe(result => {}, error => {
        console.error('Error during logout:', error);
        this.clearSession();
      });
    }
  }

  public getSigninAccount() : AccountInfo {
    return this.findAccountByPolicyName(this.mfaService.getSignInPolicyName());
  }

  private findAccountByPolicyName(policyName: string): AccountInfo | undefined {
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts) {
      return accounts.find(acc => acc.idTokenClaims.tfp.toUpperCase() === policyName.toUpperCase());
    }
  }

  private findEnrollAccount(mfaMethod: MfaMethodName): AccountInfo | undefined {
    const policyName = this.mfaService.getEnrollPolicyName(mfaMethod);
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts) {
      return accounts.find(acc => acc.idTokenClaims.tfp.toUpperCase() === policyName.toUpperCase());
    }
  }

  public clearSession() {
    sessionStorage.clear();
    localStorage.clear();
    this.msalService.instance.setActiveAccount(null);
  }
}
